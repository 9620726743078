import { useRef, useState, useEffect } from "react";
import { formatDistanceToNow } from "date-fns";

import { Avatar, TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useDispatch } from "react-redux";
import {
  getNotificationList,
  addNotificationItem,
} from "src/redux/slices/notification";
import { toggleFollow } from "src/redux/slices/user";

import useAuth from "src/hooks/useAuth";
import { useSocketContext } from "src/contexts/SocketContext";
// constant
import { BASE_AVATAR_URL } from "src/utils/constants";

export default function NotificationsPopverItem({ item }) {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const socket = useSocketContext();
  const [isFollowed, setIsFollowed] = useState(false);

  useEffect(() => {
    const flag = user?.following.includes(item.fromUser._id);
    if (flag) {
      setIsFollowed(true);
    }
  }, []);

  const handleToggleFollow = (senderId, receiverId) => {
    if (!senderId) {
      alert("please login");
      return;
    }

    if (senderId != receiverId) {
      let content = "started following you";
      if (isFollowed) {
        content = "has stopped following you.";
      }

      socket.emit("sendNotification", {
        senderId: user?._id,
        receiverId: item.fromUser._id,
        notificationContent: content,
        type: "follow",
      });

      setIsFollowed(!isFollowed);
      dispatch(toggleFollow(senderId, receiverId));
    }
  };

  return (
    <TableRow hover>
      <TableCell sx={{ width: "40px" }}>
        <Avatar src={BASE_AVATAR_URL + item.fromUser.avatarUrl} />
      </TableCell>

      <TableCell scope="row" padding="none">
        <SpanTextStyle>{item.fromUser.screenName}</SpanTextStyle>
        <SpanSubTextStyle>{item.notificationContent}</SpanSubTextStyle>
        <ClockSpanStyle>
          {formatDistanceToNow(new Date(item.createdAt))}
        </ClockSpanStyle>
      </TableCell>

      <TableCell
        align="right"
        sx={{ pr: { xs: "0 !important", sm: "24px !important" } }}
      >
        <FollowBTNStyle
          onClick={() => handleToggleFollow(user?._id, item.fromUser._id)}
        >
          {isFollowed ? "Following" : "Follow"}
        </FollowBTNStyle>
      </TableCell>
    </TableRow>
  );
}

const FollowBTNStyle = styled("button")(({ theme }) => ({
  border: "none",
  borderRadius: "4px",
  backgroundColor: "#1A82E2",
  color: "#fff",
  fontSize: "12px",
  fontFamily: "Poppins",
  padding: "6px 20px",
}));

const SpanTextStyle = styled("span")(() => ({
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: 600,
}));

const SpanSubTextStyle = styled("span")(() => ({
  fontSize: "12px",
  fontFamily: "Poppins",
  fontWeight: 400,
}));

const ClockSpanStyle = styled("span")(() => ({
  color: "#000",
  fontSize: "12px",
  fontFamily: "Poppins",
  display: "flex",
  alignItems: "center",
}));
