import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { SOCKET_ENDPOINT } from "../../utils/constants";

const initialState = {
  isLoading: false,
  error: false,
  artistCategoryList: [],
};

const slice = createSlice({
  name: "artistCategory",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE ArtistCATEGORYS
    getArtistCategoryListSuccess(state, action) {
      state.isLoading = false;
      state.artistCategoryList = action.payload;
    },

    // CREATE ArtistCategory
    createArtistCategorySuccess(state, action) {
      const newItem = action.payload;
      state.isLoading = false;
      state.artistCategoryList = [...state.artistCategoryList, newItem];
    },

    // UPDATE ArtistCategory
    updateArtistCategorySuccess(state, action) {
      const item = action.payload;
      const updateItem = map(state.artistCategoryList, (e) => {
        if (e._id === item._id) {
          return item;
        }
        return e;
      });

      state.isLoading = false;
      state.artistCategoryList = updateItem;
    },

    // DELETE Artist Category
    deleteArtistCategorySuccess(state, action) {
      const { itemId } = action.payload;
      const deleteItem = state.artistCategoryList.filter(
        (item) => item._id !== itemId,
      );
      state.isLoading = false;
      state.artistCategoryList = deleteItem;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions

export function getArtistCategoryList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/manage/category/get-artist-category`,
      );
      dispatch(slice.actions.getArtistCategoryListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// add new Artist category item
export function addArtistCategoryItem(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/manage/category/add-artist-category`,
        { name },
      );
      dispatch(slice.actions.createArtistCategorySuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete user data
export function deleteArtistCategoryItem(itemId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(
        `${SOCKET_ENDPOINT}/api/manage/category/delete-artist-category`,
        { _id: itemId },
      );
      dispatch(slice.actions.deleteArtistCategorySuccess({ itemId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
