import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import userReducer from "./slices/user";
import artworkReducer from "./slices/artwork";
import artworkPropertyReducer from "./slices/artworkProperty";
import collectionReducer from "./slices/collection";
import videoReducer from "./slices/video";
import artistCategoryReducer from "./slices/artistCategory";
import jobCategoryReducer from "./slices/jobCategory";
import artworkCategoryReducer from "./slices/artworkCategory";
import collectionCategoryReducer from "./slices/collectionCategory";
import commentReducer from "./slices/comment";
import testChatReducer from "./slices/testChat";
import analysisReducer from "./slices/analysis";
import messageReducer from "./slices/message";
import chatReducer from "./slices/chat";
import notificationReducer from "./slices/notification";
import voteReducer from "./slices/vote";
import eventReducer from "./slices/event";
import organisationReducer from "./slices/organisation";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  user: userReducer,
  artwork: artworkReducer,
  artworkProperty: artworkPropertyReducer,
  video: videoReducer,
  artistCategory: artistCategoryReducer,
  jobCategory: jobCategoryReducer,
  artworkCategory: artworkCategoryReducer,
  collectionCategory: collectionCategoryReducer,
  comment: commentReducer,
  collection: collectionReducer,
  testChat: testChatReducer,
  analysis: analysisReducer,
  message: messageReducer,
  chat: chatReducer,
  notification: notificationReducer,
  vote: voteReducer,
  event: eventReducer,
  organisation: organisationReducer
});

export { rootPersistConfig, rootReducer };
