import React, { useEffect, useState, useRef, useCallback } from "react";
import { Icon } from "@iconify/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import { Box, IconButton, Stack, InputBase } from "@mui/material";
import PopoverMenu from "./PopverMenu";
import AccountPopover from "../AccountPopover";
import AuthModal from "../../pages/auth/AuthModal";
import NotificationsPopverClient from "./NotificationsPopverClient";
import useAuth from "../../hooks/useAuth";
import { makeStyles } from "@mui/styles";
import { ForwardSharp } from "@mui/icons-material";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import LightModeIcon from "@mui/icons-material/LightMode";
import LogoIconLight from "src/components/icons/LogoIconLight";
import useSettings from "src/hooks/useSettings";
import LogoIconDark from "src/components/icons/LogoIconDark";
import { light } from "@mui/material/styles/createPalette";
import SearchIcon from '@mui/icons-material/Search';

const RootStyle = styled("div")(({ theme }) => ({
  width: "100%",
  padding: 0,
  zIndex: 100,
  position: "fixed",
  background: theme == "dark" ? "white" : "#060415",
}));

const MainContainerStyle = styled("div")(({ theme }) => ({
  width: "100%",
  padding: "15px 15px",
  margin: "0px auto",
  [theme.breakpoints.up("sm")]: {
    padding: "25px 30px",
  },
}));

const WrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

const InnerContainerStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  [theme.breakpoints.up("sm")]: {
    gap: 16,
  },
}));

const MenuListStyle = styled("div")(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: 50,
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const MobileMenuStyle = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: "inherit",
  zIndex: 1100,
  width: 0,
  height: "100%",
  transition: "all 0.5s ease 0s",
  overflow: "auto",
  background: theme == "dark" ? "white" : "#060415",
  "& > div": {
    padding: 20,
  },
}));

const MobileMenuIconStyle = styled("div")(({ theme }) => ({
  marginRight: 10,
  display: "flex",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const OverlayStyle = styled("div")(({ theme }) => ({
  position: "fixed",
  zIndex: 1000,
  width: "100%",
  height: "100%",
  background: "rgb(0 0 0 / 40%)",
  opacity: 1,
  transition: "opacity 0.3s ease 0s",
  top: 0,
  left: 0,
}));

const MobileMenuItemStyle = styled(RouterLink)(({ theme }) => ({
  fontSize: 16,
  marginTop: 15,
  cursor: "pointer",
  fontWeight: 500,
  textDecoration: "none",
  color: theme == "dark" ? "black" : "white",
}));

const LogoImageStyle = styled("img")(({ theme }) => ({
  height: "50px",
}));

const SearchBar = styled(Stack)(({theme}) => ({
  background: "#eee", 
  width: 400, 
  borderRadius: 50,

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const useStyles = makeStyles((theme) => ({
  header: {
    animation: `$slide2 0.4s`,
    animationFillMode: "forwards",
  },
  sticky: {
    animation: `$slide1 0.4s`,
    animationFillMode: "forwards",
  },
  "@keyframes slide1": {
    from: {
      opacity: 0,
      transform: "translateY(-100px)",
    },
    to: {
      opacity: 1,
      transform: "translateY(0%)",
    },
  },
  "@keyframes slide2": {
    from: {
      opacity: 1,
      transform: "translateY(0%)",
    },
    to: {
      opacity: 0,
      transform: "translateY(-100px)",
    },
  },
}));

export default function MainNavbar() {
  const { user, isAuthenticated } = useAuth();
  const ref = useRef();
  const classes = useStyles();
  const navigate = useNavigate();

  const [y, setY] = useState(0);
  const [stick, setStick] = useState(true);
  // const headerClasses = `${stick ? classes.sticky : classes.header}`;
  const { themeMode, onChangeMode, search, onChangeSearch } = useSettings();

  const handleNavigation = useCallback((e) => {
    if (y > window.scrollY || window.scrollY <= 0) {
      setStick(true);
    } else if (y < window.scrollY) {
      setStick(false);
    }
    setY(window.scrollY);
  });

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const [isOpen, setIsOpen] = useState(false);

  const menuList = [
    { name: "Explore", link: "/" },
    { name: "Artists", link: "/artists" },
    { name: "Vote", link: "/vote" },
    { name: "Exhibitions", link: "/exhibition"},
    // { name: "Collections", link: "/collections" },
    // { name: "About Us", link: "/about" },
  ];

  const handleMessage = () => {
    if (!user) {
      alert("Please login.");
      return;
    }
    navigate("/messageboard/inbox");
  };

  const changeThemeMode = () => {
    themeMode == "light" ? onChangeMode("dark") : onChangeMode("light");
  };

  return (
    <>
      <RootStyle ref={ref} theme={themeMode}>
        <WrapperStyle>
          <MainContainerStyle>
            <InnerContainerStyle>
              <Box component={RouterLink} to="/">
                {themeMode == "dark" ? <LogoIconLight /> : <LogoIconDark />}
              </Box>
              <MenuListStyle>
                {menuList.map((item, index) => (
                  <PopoverMenu key={index} item={item} theme={themeMode} />
                ))}
              </MenuListStyle>
              <SearchBar
                mb={1}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <IconButton sx={{ p: '10px' }} aria-label="menu">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search ..."
                  value={search}
                  onChange={(e) => onChangeSearch(e.target.value)}
                />
              </SearchBar>
              <IconWrapperStyle>
                <MobileMenuIconStyle
                  onClick={() => setIsOpen(true)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon
                    icon="ic:round-menu"
                    width="24"
                    color={themeMode == "dark" ? "black" : "white"}
                  />
                </MobileMenuIconStyle>
                {/* {themeMode == "dark" ? (
                  <Brightness3Icon
                    onClick={changeThemeMode}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <LightModeIcon
                    onClick={changeThemeMode}
                    style={{ cursor: "pointer" }}
                  />
                )} */}
                <NotificationsPopverClient theme={themeMode} />
                <IconButton size="small" onClick={handleMessage}>
                  <Icon
                    icon="ant-design:comment"
                    width="22"
                    color={themeMode == "dark" ? "black" : "white"}
                  />
                </IconButton>
                {!isAuthenticated && <AuthModal />}
                {isAuthenticated && <AccountPopover />}
              </IconWrapperStyle>
            </InnerContainerStyle>
          </MainContainerStyle>
        </WrapperStyle>
      </RootStyle>
      <MobileMenuStyle sx={{ width: isOpen ? "50%" : "0px" }} theme={themeMode}>
        <Box sx={{ display: "grid"}}>
          {menuList.map((menu, i) => (
            <MobileMenuItemStyle
              key={i}
              to={menu.link}
              onClick={() => setIsOpen(false)}
              theme={themeMode}
            >
              {menu.name}
            </MobileMenuItemStyle>
          ))}
        </Box>
      </MobileMenuStyle>
      {isOpen && <OverlayStyle onClick={() => setIsOpen(false)} />}
    </>
  );
}
