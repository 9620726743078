import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { SOCKET_ENDPOINT } from "../../utils/constants";

const initialState = {
  isLoading: false,
  error: false,
  artworkCategoryList: [],
};

const slice = createSlice({
  name: "artworkCategory",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE ARTWORKCATEGORYS
    getArtworkCategoryListSuccess(state, action) {
      state.isLoading = false;
      state.artworkCategoryList = action.payload;
    },

    // CREATE ArtworkCategory
    createArtworkCategorySuccess(state, action) {
      const newItem = action.payload;
      state.isLoading = false;
      state.artworkCategoryList = [...state.artworkCategoryList, newItem];
    },

    // UPDATE ArtworkCategory
    updateArtworkCategorySuccess(state, action) {
      const item = action.payload;
      const updateItem = map(state.artworkCategoryList, (e) => {
        if (e._id === item._id) {
          return item;
        }
        return e;
      });

      state.isLoading = false;
      state.artworkCategoryList = updateItem;
    },

    // DELETE Artwork Category
    deleteArtworkCategorySuccess(state, action) {
      const { itemId } = action.payload;
      const deleteItem = filter(
        state.artworkCategoryList,
        (item) => item._id !== itemId,
      );
      state.isLoading = false;
      state.artworkCategoryList = deleteItem;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions

export function getArtworkCategoryList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/manage/category/get-artwork-category`,
      );

      dispatch(slice.actions.getArtworkCategoryListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// add new artwork category item
export function addArtworkCategoryItem(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/manage/category/add-artwork-category`,
        { name },
      );
      dispatch(
        slice.actions.createArtworkCategorySuccess(response.data.result),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete artwork category item
export function deleteArtworkCategoryItem(itemId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(
        `${SOCKET_ENDPOINT}/api/manage/category/delete-artwork-category`,
        { id: itemId },
      );
      dispatch(slice.actions.deleteArtworkCategorySuccess({ itemId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
