import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import axios from "../../utils/axios";
import { SOCKET_ENDPOINT } from "../../utils/constants";

const initialState = {
  isLoading: false,
  error: false,
  organisationList: [],
  oneOrganisation: null
};

const slice = createSlice({
  name: "organisation",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE organisations
    getOrganisationListSuccess(state, action) {
      state.isLoading = false;
      state.organisationList = action.payload;
    },

    // get Organisation detail by ID
    getOneOrganisationSuccess(state, action) {
      state.isLoading = false;
      state.oneOrganisation = action.payload;
    },

    // CREATE organisation
    createOrganisationSuccess(state, action) {
      const newItem = action.payload;
      state.isLoading = false;
      state.organisationList = [...state.organisationList, newItem];
    },

    // DELETE organisation
    deleteOrganisationSuccess(state, action) {
      const { itemId } = action.payload;
      const deleteItem = filter(state.organisationList, (item) => item._id !== itemId);
      state.isLoading = false;
      state.organisationList = deleteItem;
    },

    // Update organisation
    updateOrganisationSuccess(state, action) {
      const item = action.payload;
      const updateItem = map(state.organisationList, (e) => {
        if (e._id === item._id) {
          return item;
        }
        return e;
      });

      state.isLoading = false;
      state.organisationList = updateItem;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
//=====For Admin========

export function getOrganisationList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/manage/organisation/get-organisation`,
      );
      dispatch(slice.actions.getorganisationListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//=====For Client========

export function getOrganisationListClient() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/organisation/get-organisations`,
      );
      dispatch(slice.actions.getOrganisationListSuccess(response.data.organisationList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// get one organisation
export function getOrganisationDetailsById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/organisation/get-oneOrganisation`,
        {id},
      );
      dispatch(slice.actions.getOneOrganisationSuccess({...response.data.result, events: response.data.events}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// add new organisation
export function addOrganisation(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/organisation/add-organisation`,
        values,
      );
      dispatch(slice.actions.createOrganisationSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete organisation
export function deleteOrganisation(itemId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/organisation/delete-organisation`,
        { itemId },
      );
      dispatch(slice.actions.deleteOrganisationSuccess({ itemId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// update organisation
export function updateOrganisation(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/organisation/update-organisation`,
        values,
      );
      // dispatch(slice.actions.updateorganisationSuccess(response.data.organisation));
      dispatch(slice.actions.updateOrganisationSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
