import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import axios from "../../utils/axios";
import { SOCKET_ENDPOINT } from "../../utils/constants";

const initialState = {
  isLoading: false,
  error: false,
  videoList: [],
};

const slice = createSlice({
  name: "video",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE Videos
    getVideoListSuccess(state, action) {
      state.isLoading = false;
      state.videoList = action.payload;
    },

    // CREATE Video
    createVideoSuccess(state, action) {
      const newItem = action.payload;
      state.isLoading = false;
      state.videoList = [...state.videoList, newItem];
    },

    // DELETE Video
    deleteVideoSuccess(state, action) {
      const { itemId } = action.payload;
      const deleteItem = filter(state.videoList, (item) => item._id !== itemId);
      state.isLoading = false;
      state.videoList = deleteItem;
    },

    // Update Video
    updateVideoSuccess(state, action) {
      const item = action.payload;
      const updateItem = map(state.videoList, (e) => {
        if (e._id === item._id) {
          return item;
        }
        return e;
      });

      state.isLoading = false;
      state.videoList = updateItem;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
//=====For Admin========

export function getVideoList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/manage/video/get-video`,
      );
      dispatch(slice.actions.getVideoListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//=====For Client========

export function getVideoListClient() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/video/get-videos`,
      );
      dispatch(slice.actions.getVideoListSuccess(response.data.videoList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// add new video
export function addVideo(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/video/add-video`,
        values,
      );
      dispatch(slice.actions.createVideoSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete video
export function deleteVideo(itemId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/video/delete-video`,
        { itemId },
      );
      dispatch(slice.actions.deleteVideoSuccess({ itemId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// update video
export function updateVideo(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/video/update-video`,
        values,
      );
      // dispatch(slice.actions.updateVideoSuccess(response.data.event));
      dispatch(slice.actions.updateVideoSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
