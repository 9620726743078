import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import axios from "../../utils/axios";
import { SOCKET_ENDPOINT } from "../../utils/constants";

const initialState = {
  isLoading: false,
  error: false,
  eventList: [],
  oneEvent: null
};

const slice = createSlice({
  name: "event",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE events
    getEventListSuccess(state, action) {
      state.isLoading = false;
      state.eventList = action.payload;
    },

    // get Event detail by ID
    getOneEventSuccess(state, action) {
      state.isLoading = false;
      state.oneEvent = action.payload;
    },

    // CREATE event
    createEventSuccess(state, action) {
      const newItem = action.payload;
      state.isLoading = false;
      state.eventList = [...state.eventList, newItem];
    },

    // DELETE event
    deleteEventSuccess(state, action) {
      const { itemId } = action.payload;
      const deleteItem = filter(state.eventList, (item) => item._id !== itemId);
      state.isLoading = false;
      state.eventList = deleteItem;
    },

    // Update event
    updateEventSuccess(state, action) {
      const item = action.payload;
      const updateItem = map(state.eventList, (e) => {
        if (e._id === item._id) {
          return item;
        }
        return e;
      });

      state.isLoading = false;
      state.eventList = updateItem;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
//=====For Admin========

export function getEventList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/manage/event/get-event`,
      );
      dispatch(slice.actions.getEventListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//=====For Client========

export function getEventListClient() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/event/get-events`,
      );
      dispatch(slice.actions.getEventListSuccess(response.data.eventList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// get one event
export function getEventDetailsById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/event/get-oneEvent`,
        {id},
      );
      dispatch(slice.actions.getOneEventSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// add new event
export function addEvent(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/event/add-event`,
        values,
      );
      dispatch(slice.actions.createEventSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete event
export function deleteEvent(itemId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/event/delete-event`,
        { itemId },
      );
      dispatch(slice.actions.deleteEventSuccess({ itemId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// update event
export function updateEvent(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/event/update-event`,
        values,
      );
      // dispatch(slice.actions.updateeventSuccess(response.data.event));
      dispatch(slice.actions.updateEventSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
