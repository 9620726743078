import { Outlet, Link, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";

import { Icon } from "@iconify/react";
import homeIcon from "@iconify/icons-ic/outline-home";
import notificationIcon from "@iconify/icons-ic/outline-notifications";
import exhibitionIcon from "@iconify/icons-ant-design/environment-filled";
import messgeIcon from "@iconify/icons-eva/message-circle-outline";
import searchIcon from "@iconify/icons-ant-design/search-outline";
import voteIcon from "@iconify/icons-ant-design/rise-outline";
import artistIcon from "@iconify/icons-eva/person-outline";

import { Box, Grid, Paper } from "@mui/material";

import MainNavbar from "./MainNavbar";
import MainFooter from "./MainFooter";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const SideBar = styled("div")(({ theme }) => ({
  display: "flex",
  width: "30%",
  padding: "50px",
  paddingTop: "150px",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const SideBarListWrapper = styled("ul")(() => ({
  position: "fixed",
  listStyle: "none",
  fontSize: "24px",
  color: "gray",
  // fontFamily: "Avenir Next Demi, Helvetica, Arial, sans-serif",
  lineHeight: "2.5",
  zIndex: 99
}))

const SideBarItem = styled("li")(() => ({
  display: "flex",
  alignItems: "center",
}))

const IconWrapper = styled(Box)(() => ({
  width: 32,
  height: 32,
  marginRight: "20px"
}))

const SideBarLink = styled(Link)(() => ({
  color: "gray",
  textDecoration: "none",
}))

const sidebarDate = [
  {icon: homeIcon, text: "Explore", url: "/"},
  {icon: artistIcon, text: "Artists", url: "/artists"},
  {icon: voteIcon, text: "Vote", url: "/vote"},
  {icon: exhibitionIcon, text: "What's on", url: "/exhibition"},
  {icon: searchIcon, text: "Search", url: "/search"},
  {icon: notificationIcon, text: "Notifications", url: "/notifications"},
  {icon: messgeIcon, text: "Messages", url: "/messageboard/inbox"},
]

export default function MainLayout() {

  const { pathname } = useLocation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100vh",
      }}
    >
      <MainNavbar />
      <Grid container spacing={2}>
        <Grid item md={2} xs={0}>
          <Item>
            <SideBar>
              <SideBarListWrapper>
                { sidebarDate.map((item, index) => (
                  <SideBarItem key={index}>
                    <IconWrapper
                      component={Icon}
                      icon={item.icon}
                      className={pathname === item.url && "link-active"}
                    />
                    <SideBarLink to={item.url} className={pathname === item.url && "link-active"}>{item.text}</SideBarLink>
                  </SideBarItem>
                ))}
              </SideBarListWrapper>
            </SideBar>
          </Item>
        </Grid>
        <Grid item md={10} xs={12}>
          <Outlet/>
        </Grid>
      </Grid>
      <MainFooter />
    </div>
  );
}
