import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { Icon } from "@iconify/react";
import { styled } from "@mui/material/styles";
import { Stack, Dialog, DialogContent, TextField } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import useSettings from "src/hooks/useSettings";
import useAuth from "../../hooks/useAuth";

const MainContainerStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "auto",
  width: "fit-content",
  textAlign: "center",
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.mode === "dark" ? "black" : "white",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.mode === "dark" ? "black" : "white",
    },
  },
}));

const TitleContainerStyle = styled("div")(({ theme }) => ({
  marginBottom: "40px",
}));

const TitleStyle = styled("p")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 700,
}));

const SubTitleStyle = styled("p")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
}));

const SendLinkBTNStyle = styled("button")(({ theme }) => ({
  color: "#fff",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  borderRadius: "5px",
  border: "none",
  backgroundColor: "#F30E3E",
  padding: "10px 50px",
  marginTop: "10px",
}));

const DividerStyle = styled("div")(({ theme }) => ({
  width: "80%",
  height: "1px",
  backgroundColor: "#7C7C7C",
  margin: "40px 0 20px",
}));

export default function ForgotPassword({
  forgot,
  setForgot,
  handleLoginModal,
}) {
  const { user, forgotPassword } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { themeMode } = useSettings();

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },

    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const res = await forgotPassword(values.email);
        if (res.status == true) {
          navigate("/password/forgot/success/" + values.email);
        }
        resetForm();
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors({ afterSubmit: error.code });
      }
    },
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <Dialog fullWidth open={forgot} onClose={() => setForgot(false)}>
      <DialogContent>
        <MainContainerStyle>
          <TitleContainerStyle>
            <TitleStyle>Forgot your password?</TitleStyle>
            <SubTitleStyle>Beleivers in the art.</SubTitleStyle>
          </TitleContainerStyle>
          <Stack spacing={3} sx={{ px: "15%" }}>
            <SubTitleStyle>
              Enter your email and we'll send you a link to get back into your
              account.
            </SubTitleStyle>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <CustomTextField
                  fullWidth
                  label="Email Address"
                  size="small"
                  {...getFieldProps("email")}
                  theme={{ palette: { mode: themeMode } }}
                />
                <SendLinkBTNStyle type="submit">Send Link</SendLinkBTNStyle>
              </Form>
            </FormikProvider>
          </Stack>

          <DividerStyle />

          <SubTitleStyle>
            Already have an account?{" "}
            <span
              style={{ textDecoration: "underline" }}
              onClick={handleLoginModal}
            >
              Sign in
            </span>
          </SubTitleStyle>
        </MainContainerStyle>
      </DialogContent>
    </Dialog>
  );
}
