import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { styled } from "@mui/material/styles";
import useSettings from "src/hooks/useSettings";
import {
  Stack,
  Alert,
  Checkbox,
  Dialog,
  DialogContent,
  TextField,
  IconButton,
  FormControlLabel,
  InputAdornment,
  Divider,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
// google auth
import {
  GoogleLogin,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import { client_id } from "src/utils/constants";
import { SOCKET_ENDPOINT } from "src/utils/constants";
import axios from "axios";
import { setSession } from "src/utils/jwt";

const MainContainerStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
}));

const TitleContainerStyle = styled("div")(({ theme }) => ({
  marginTop: "15px",
  marginBottom: "30px",
}));

const TitleStyle = styled("p")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 700,
}));

const SubTitleStyle = styled("p")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
}));

const RecoverTextStyle = styled("p")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  textDecoration: "underline",
}));

const DividerStyle = styled("div")(({ theme }) => ({
  width: "80%",
  height: "1px",
  backgroundColor: "#7C7C7C",
  margin: "30px 0 20px",
}));

const LoginBTNStyle = styled("button")(({ theme }) => ({
  color: "#fff",
  width: "100%",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  borderRadius: "5px",
  border: "none",
  backgroundColor: "#F30E3E",
  padding: "10px 50px",
  cursor: "pointer",
}));

const GoogleBTNStyle = styled("button")(({ theme }) => ({
  color: "#000",
  maxWidth: "250px",
  width: "100%",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  borderRadius: "5px",
  border: "none",
  backgroundColor: "#EDEFF1",
  padding: "10px 50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.mode === "dark" ? "black" : "white",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.mode === "dark" ? "black" : "white",
    },
  },
}));

export default function SignIn({
  loginModal,
  setLoginModal,
  handleSignUpModal,
  handleForgotModal,
  handleAccountTypeModal
}) {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState();
  const { themeMode } = useSettings();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await login(values.email, values.password, values.remember);
        navigate("/");
      } catch (error) {
        console.error(error);
        resetForm();
        setError(error.message);
        // setErrors({ afterSubmit: error.message });
        if (error.code == 3) {
          localStorage.setItem("currentEmail", values.email);
          // navigate('/signup/email-verify/')
          navigate("/");
        }
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      try {
        const res = await axios.post(
          `${SOCKET_ENDPOINT}/api/signinWithGoogle`,
          { code: codeResponse.code },
        );
        if (res.data.status == true) {
          setSession(res.data.accessToken);
          window.location.reload("/");
        } else {
          setError(res.data.message);
          if (res.data.code == 3) {
            localStorage.setItem("currentEmail", res.data.email);
            navigate("/signup/email-verify/");
            // navigate('/')
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  return (
    <>
      <IconButton size="small" onClick={() => setLoginModal(true)}>
        <Icon
          icon="ant-design:user-outlined"
          width="24"
          height="24"
          color={themeMode == "dark" ? "black" : "white"}
        />
      </IconButton>
      <Dialog fullWidth open={loginModal} onClose={() => setLoginModal(false)}>
        <DialogContent>
          <MainContainerStyle>
            <TitleContainerStyle>
              <TitleStyle>
                Welcome to&nbsp;
                <span style={{ fontFamily: "ThankYouSoMuch", fontWeight: 400 }}>
                  <span style={{ color: "#F30E3E" }}>A</span>rtshole
                </span>
                <span style={{ color: "#F30E3E" }}>.</span>
              </TitleStyle>
              <SubTitleStyle>Beleivers in the art.</SubTitleStyle>
            </TitleContainerStyle>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  {error && <Alert severity="error">{error}</Alert>}

                  <CustomTextField
                    fullWidth
                    autoComplete="username"
                    type="email"
                    label="Email"
                    size="small"
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    theme={{ palette: { mode: themeMode } }}
                  />

                  <CustomTextField
                    fullWidth
                    autoComplete="current-password"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    size="small"
                    {...getFieldProps("password")}
                    theme={{ palette: { mode: themeMode } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={
                      (touched.password && errors.password) ||
                      "Password must be minimum 8+"
                    }
                  />
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ my: 2 }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...getFieldProps("remember")}
                        checked={values.remember}
                        color="default"
                        size="small"
                      />
                    }
                    label={<SubTitleStyle>Remember me</SubTitleStyle>}
                  />

                  <RecoverTextStyle onClick={handleForgotModal}>
                    Forgot Password?
                  </RecoverTextStyle>
                </Stack>

                <LoginBTNStyle type="submit">Login</LoginBTNStyle>
                <Divider sx={{ my: 1 }}>
                  <SubTitleStyle>Or</SubTitleStyle>
                </Divider>
              </Form>
            </FormikProvider>

            <GoogleBTNStyle onClick={() => googleLogin()}>
              <Icon icon="flat-color-icons:google" width="22" />
              &nbsp;&nbsp;&nbsp;Login with Google
            </GoogleBTNStyle>

            <DividerStyle />

            <SubTitleStyle>
              Don't have an account?{" "}
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={handleAccountTypeModal}
              >
                Sign up
              </span>
            </SubTitleStyle>
          </MainContainerStyle>
        </DialogContent>
      </Dialog>
    </>
  );
}
