import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { SOCKET_ENDPOINT } from "../../utils/constants";

function objFromArray(array, key = "id") {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: false,
  messageList: [],
  newMessage: null,
};

const slice = createSlice({
  name: "message",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Messages
    getMessageListSuccess(state, action) {
      state.isLoading = false;
      state.messageList = action.payload;
    },

    // GET Message
    newMessageSuccess(state, action) {
      state.isLoading = false;
      state.newMessage = action.payload;
      state.messageList.push(action.payload);
    },

    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const conversation = action.payload;
      const {
        conversationId,
        messageId,
        message,
        contentType,
        attachments,
        createdAt,
        senderId,
      } = conversation;

      const newMessage = {
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
      };

      state.conversations.byId[conversationId].messages.push(newMessage);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onSendMessage, newMessageSuccess } = slice.actions;

export function getAllMessages(chatId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/message/get-messages/${chatId}`,
      );
      dispatch(slice.actions.getMessageListSuccess(response.data.messageList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendMessage(msgData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const config = { header: { "Content-Type": "application/json" } };
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/message/new-message`,
        msgData,
        config,
      );
      dispatch(slice.actions.newMessageSuccess(response.data.newMessage));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
