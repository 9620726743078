import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { styled } from "@mui/material/styles";
import {
  Stack,
  Alert,
  Checkbox,
  Dialog,
  DialogContent,
  TextField,
  IconButton,
  FormControlLabel,
  InputAdornment,
  Divider,
} from "@mui/material";
import useAuth from "src/hooks/useAuth";

import {
  GoogleLogin,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import { client_id } from "src/utils/constants";
import { SOCKET_ENDPOINT } from "src/utils/constants";
import axios from "axios";
import useSettings from "src/hooks/useSettings";

import { ReactComponent as ArtistIcon } from '../../assets/img/artist.svg';

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.mode === "dark" ? "black" : "white",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.mode === "dark" ? "black" : "white",
    },
  },
}));

const JoinAsBtn = styled("button")(() => ({
  background: "#108a00", 
  outline: "none", 
  border: "none", 
  padding: "10px 24px", 
  borderRadius: "50px", 
  color: "white", 
  fontWeight: "600", 
  fontSize: "16px", 
  margin: "30px 0"
}))

const VoterIcon = styled("img")({
  color: "black",
  width: "30px",
  height: "30px",
  paddingLeft: "3px"
});

export default function SignUp({
  signupModal,
  setSignUpModal,
  handleLoginModal,
  otherPlan
  // handleArtistModal,
}) {
  const { register } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const { themeMode } = useSettings();

  const [joinAs, setJoinAs] = useState("");
  // const [isShow, setIsShow] = useState(otherPlan);

  // const toggleJoin = () => {
  //   if(joinAs === "artist") {
  //     setJoinAs("voter");
  //   } else {
  //     setJoinAs("artist");
  //   }
  // }

  const SignUpSchema = Yup.object().shape({
    fullName: Yup.string().required("Full name is required"),
    screenName: Yup.string().required("Display name is required"),
    email: Yup.string().required("Email is required").email(),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      role: "client",
      fullName: "",
      email: "",
      screenName: "",
      password: "",
      subscribe: false,
    },
    validationSchema: SignUpSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const regexp = /^\S*$/;
        // if (!regexp.test(values.screenName)) {
        //   alert("please delete space on username");
        //   return;
        // }

        await register(
          values.role,
          values.fullName,
          values.email,
          values.screenName,
          values.password,
          values.subscribe,
        );
        setSuccess(true);
        resetForm();
        // navigate('/signup/email-verify');
        // window.location.reload("/");
      } catch (error) {
        setErrors({ afterSubmit: error.message });
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const googleRegister = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      const res = await axios.post(`${SOCKET_ENDPOINT}/api/signupWithGoogle`, {
        code: codeResponse.code,
      });
      localStorage.setItem("currentEmail", res.data.email);
      window.location.href = "/";
      setSignUpModal(false);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  return (
    <Dialog fullWidth open={signupModal} onClose={() => setSignUpModal(false)}>
      <DialogContent>
        <MainContainerStyle>
          <TitleContainerStyle>
            <TitleStyle>
              Welcome to&nbsp;
              <span style={{ fontFamily: "ThankYouSoMuch", fontWeight: 400 }}>
                <span style={{ color: "#F30E3E" }}>A</span>rtshole
              </span>
              <span style={{ color: "#F30E3E" }}>.</span>
            </TitleStyle>
            <SubTitleStyle>Beleivers in the art.</SubTitleStyle>
          </TitleContainerStyle>

          <SubTitleStyle sx={{ mb: "20px" }}>
            Already have an account?{" "}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={handleLoginModal}
            >
              {" "}
              Sign In
            </span>
          </SubTitleStyle>

          {/* {
            isShow && 
            <>
              <div>
                <div className={`signup-join-as ${joinAs === "exhibition" ? "active" : ""}`}>
                  <div className="select-bar">
                    <ArtistIcon/>
                    <div className="icon-wrapper">
                      <div className="icon-content"></div>
                    </div>
                  </div>
                  <input onClick={() => setJoinAs("exhibition")}/>
                  <h4>I am an exhibition owner, post exhibition</h4>
                </div>
                <div className={`signup-join-as ${joinAs === "voter" ? "active" : ""}`}>
                  <div className="select-bar">
                    <VoterIcon src="img/voter.png"/>
                    <div className="icon-wrapper">
                      <div className="icon-content"></div>
                    </div>
                  </div>
                  <input onClick={() => setJoinAs("voter")}/>
                  <h4>I am a voter & artwork lover, vote artworks</h4>
                </div>
              </div>

              <JoinAsBtn disabled={joinAs === ""} onClick={() => setIsShow(false)}>{joinAs === "exhibition" ? "Join for Exhibition post" : "Join as a Voter"}</JoinAsBtn>  
            </>
          } */}
          
          
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <TextField name="role" sx={{ display: "none" }} />

            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              {success && (
                <Alert severity="success" color="info">
                  Registered Successfully!
                </Alert>
              )}

              <CustomTextField
                fullWidth
                autoComplete="fullName"
                type="fullName"
                label="User Name"
                size="small"
                {...getFieldProps("fullName")}
                error={Boolean(touched.fullName && errors.fullName)}
                helperText={touched.fullName && errors.fullName}
                theme={{ palette: { mode: themeMode } }}
              />

              <CustomTextField
                fullWidth
                autoComplete="screenName"
                type="screenName"
                label="Display name"
                size="small"
                {...getFieldProps("screenName")}
                error={Boolean(touched.screenName && errors.screenName)}
                helperText={touched.screenName && errors.screenName}
                theme={{ palette: { mode: themeMode } }}
              />

              <CustomTextField
                fullWidth
                autoComplete="email"
                type="email"
                label="Email"
                size="small"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                theme={{ palette: { mode: themeMode } }}
              />

              <CustomTextField
                fullWidth
                autoComplete="password"
                type={showPassword ? "text" : "password"}
                label="Password"
                size="small"
                {...getFieldProps("password")}
                theme={{ palette: { mode: themeMode } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={
                  (touched.password && errors.password) ||
                  "Password must be minimum 8+"
                }
              />
            </Stack>

            <Stack sx={{ my: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...getFieldProps("subscribe")}
                    checked={values.subscribe}
                    color="default"
                    size="small"
                  />
                }
                label={
                  <SubTitleStyle>
                    Subscribe to Artshole newsletter
                  </SubTitleStyle>
                }
              />
            </Stack>
            
            <SignUpBTNStyle type="submit">Sign Up</SignUpBTNStyle>
            
            <Divider sx={{ my: 1 }}>
              <SubTitleStyle>Or</SubTitleStyle>
            </Divider>
          </Form>
        </FormikProvider>
        
        <GoogleBTNStyle onClick={() => googleRegister()}>
          <Icon icon="flat-color-icons:google" width="22" />
          &nbsp;&nbsp;&nbsp;Signup with Google
        </GoogleBTNStyle>  
          
        </MainContainerStyle>
      </DialogContent>
    </Dialog>
  );
}

const MainContainerStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
}));

const TitleContainerStyle = styled("div")(({ theme }) => ({
  marginTop: "15px",
  marginBottom: "15px",
}));

const TitleStyle = styled("p")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 700,
}));

const SubTitleStyle = styled("p")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
}));

const SignUpBTNStyle = styled("button")(({ theme }) => ({
  color: "white",
  width: "100%",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  borderRadius: "5px",
  border: "none",
  backgroundColor: "#F30E3E",
  padding: "10px",
  cursor: "pointer",
  margin: "0 2%"
}));

const GoogleBTNStyle = styled("button")(({ theme }) => ({
  maxWidth: "250px",
  width: "100%",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  borderRadius: "5px",
  border: "none",
  backgroundColor: "#EDEFF1",
  padding: "10px 50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
}));
