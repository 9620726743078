import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { SOCKET_ENDPOINT } from "../../utils/constants";

const initialState = {
  isLoading: false,
  error: false,
  notificationList: [],
  newNotification: null,
};

const slice = createSlice({
  name: "Notification",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE NotificationS
    getNotificationListSuccess(state, action) {
      state.isLoading = false;
      state.notificationList = action.payload;
    },

    // Add New Notification
    addNotificationSuccess(state, action) {
      state.newNotification = action.payload;
      state.isLoading = false;
      // state.notificationList.push(action.payload);
    },

    // CREATE Notification
    newNotificationSuccess(state, action) {
      state.isLoading = false;
      state.newNotification = action.payload;
      state.notificationList.push(action.payload);
    },

    // Read Notifications
    readNotificationSuccess(state, action) {
      const userId = action.payload;
      const updateItem = map(state.notificationList, (e) => {
        e.isUnRead = false;
        return e;
      });

      state.isLoading = false;
      state.notificationList = updateItem;
    },

    // DELETE Artwork Category
    deleteNotificationsSuccess(state, action) {
      const { itemIds } = action.payload;
      const updatedList = filter(state.notificationList, (e) => {
        if (itemIds.findIndex(item => item.toString() === e._id.toString()) < 0 ) {
          return true;
        } else {
          return false;
        }
      })
      // let deleteItem;
      // itemIds.map((e) => {
      //     deleteItem = deleteItem.filter((item) => item._id !== e);
      // })

      // itemIds.filter((e) => {
      //     deleteItem.filter((item) => item._id !== e)
      // })

      state.isLoading = false;
      state.notificationList = updatedList;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addNotificationSuccess, newNotificationSuccess } = slice.actions;

export function getNotificationList(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/notification/get-notifications`,
        { userId },
      );
      dispatch(slice.actions.getNotificationListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// add new notification
export function addNotificationItem(notificationData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/notification/add-notification`,
        notificationData,
      );
      dispatch(slice.actions.addNotificationSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// read all notifications
export function readNotifications(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/notification/read-notifications`,
        { userId },
      );
      dispatch(slice.actions.readNotificationSuccess(userId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete notification
export function deleteNotifications(itemIds) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(
        `${SOCKET_ENDPOINT}/api/notification/delete-notifications`,
        { itemIds },
      );
      dispatch(slice.actions.deleteNotificationsSuccess({ itemIds }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
