import { useContext, createContext } from "react";
import { io } from "socket.io-client";
import { SOCKET_ENDPOINT } from "src/utils/constants";

const SocketContext = createContext();

export default function SocketContextProvider({ children }) {
  const socket = io(SOCKET_ENDPOINT);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
}

export const useSocketContext = () => useContext(SocketContext);

// import socketio from "socket.io-client";
// import { SOCKET_URL } from "config";

// export const socket = socketio.connect(SOCKET_URL);
// export const SocketContext = React.createContext();
