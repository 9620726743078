import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import axios from "../../utils/axios";
import { SOCKET_ENDPOINT } from "../../utils/constants";

const initialState = {
  isLoading: false,
  error: false,
  voteList: [],
  oneVote: null,
  currentVote: null,
  hasMore: true,
  index: 0,
  step: 8,
};

const slice = createSlice({
  name: "vote",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    createVoteSuccess(state, action) {
      const newItem = action.payload;
      state.isLoading = false;
      state.voteList = [...state.voteList, newItem];
    },

    // GET MANAGE USERS
    getVoteListSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.status) {
        state.voteList = action.payload.voteList;  
      }
    },

    // GET userList INFINITE
    getVoteListInitial(state, action) {
      state.isLoading = false;
      state.voteList = action.payload;
    },

    // GET one user by id
    getOneVoteSuccess(state, action) {
      state.isLoading = false;
      state.oneVote = action.payload.oneVote;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // DELETE USERS
    deleteOneVote(state, action) {
      const deleteVote = filter(
        state.voteList,
        (user) => user._id !== action.payload,
      );
      state.userList = deleteUser;
    },

    // GET CURRENT VOTE INFO
    getCurrentVoteSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.status) {
        state.currentVote = action.payload.currentVote;  
      }
    },

    submitArtworkforVoteSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.status) {
        // state.currentVote = action.payload.currentVote;  
      }
    },

    cancelArtworkforVoteSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.status) {
        // state.currentVote = action.payload.currentVote;  
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreUserList } = slice.actions;

export function getVoteList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/manage/vote/get-votes`,
      );
      dispatch(slice.actions.getVoteListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOneVote(voteId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/manage/vote/view-vote`,
        {'id': voteId}
      );
      dispatch(slice.actions.getOneVoteSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// 
export function getCurrentVote() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/vote/current`,
      );
      dispatch(slice.actions.getCurrentVoteSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete vote data
export function deleteVote(voteId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/manage/vote/delete-vote`,
        { voteId },
      );
      dispatch(slice.actions.deleteOneUser(voteId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addVote(formData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/manage/vote/add-vote`,
        formData,
      );
      dispatch(slice.actions.createVoteSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//client

export function submitArtworkforVote(artworkId, currentVote) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/vote/add-artwork`,
        {
          "artworkId": artworkId,
          "currentVote": currentVote
        },
      );
      dispatch(slice.actions.submitArtworkforVoteSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cancelArtworkforVote(artworkId, currentVote) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/vote/cancel-artwork`,
        {
          "artworkId": artworkId,
          "currentVote": currentVote
        },
      );
      dispatch(slice.actions.cancelArtworkforVoteSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}