const Dev_Mode = false;

// export const SOCKET_ENDPOINT =
//   Dev_Mode === true ? "http://localhost:5000" : "https://api.artshole.com";
export const SOCKET_ENDPOINT =
  Dev_Mode === true
    ? "http://localhost:5000"
    : "https://artshole.com";
export const BASE_AVATAR_URL = `${SOCKET_ENDPOINT}/uploads/avatar/`;
export const BASE_COVER_URL = `${SOCKET_ENDPOINT}/uploads/cover/`;
export const BASE_ART_IMAGE_URL = `${SOCKET_ENDPOINT}/uploads/artwork/`;
export const BASE_ORGANISATION_URL = `${SOCKET_ENDPOINT}/uploads/organisation/`;
export const BASE_EVENT_URL = `${SOCKET_ENDPOINT}/uploads/event/`;
//
// export const client_id = "997382936032-ccq0g21lfs1s6v8birtpf70mq0t5vs4l.apps.googleusercontent.com"
// export const project_id = "art-project-1673631765209"
// export const auth_uri = "https://accounts.google.com/o/oauth2/auth"
// export const token_uri = "https://oauth2.googleapis.com/token"
// export const auth_provider_x509_cert_url = "https://www.googleapis.com/oauth2/v1/certs"
// export const client_secret = "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"

export const client_id =
  Dev_Mode === true
    ? "479581625315-9ovq1rkna2okhblb7fum30fqedr17drf.apps.googleusercontent.com"
    : "490865311305-o54mjc508bpfga6rplf9g4kdsd2g0opi.apps.googleusercontent.com";
export const client_secret =
  Dev_Mode === true
    ? "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"
    : "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";
// export const client_id = "186824129508-pgnkntibrnd7geluta082vnbgi1jchmr.apps.googleusercontent.com"
// export const project_id = "artshole";
export const project_id = "islamic-e9cfb";
export const auth_uri = "https://accounts.google.com/o/oauth2/auth";
export const token_uri = "https://oauth2.googleapis.com/token";
export const auth_provider_x509_cert_url =
  "https://www.googleapis.com/oauth2/v1/certs";
// export const client_secret = "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"
