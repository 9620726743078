import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import homeFill from "@iconify/icons-eva/home-fill";
import personFill from "@iconify/icons-eva/person-fill";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import { Link as RouterLink } from "react-router-dom";
import { alpha } from "@mui/material/styles";
import {
  Avatar,
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
} from "@mui/material";
import { MIconButton } from "../components/@material-extend";
import MenuPopover from "../components/MenuPopover";
import useAuth from "../hooks/useAuth";
import useIsMountedRef from "../hooks/useIsMountedRef";
import { BASE_AVATAR_URL } from "src/utils/constants";

export default function AccountPopover() {
  const { user, logout } = useAuth();
  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();

  let MENU_OPTIONS;
  if (user) {
    if (user.role == "admin") {
      MENU_OPTIONS = [
        { label: "Admin Panel", icon: homeFill, linkTo: "/admin" },
        { label: "Upload", icon: settings2Fill, linkTo: `/upload` },
        {
          label: "Profile",
          icon: personFill,
          linkTo: `/${user?.screenName}`,
        },
        { label: "Settings", icon: settings2Fill, linkTo: `/account` },
      ];
    } else if (user.role === 'artist') {
      MENU_OPTIONS = [
        { label: "Upload", icon: settings2Fill, linkTo: `/upload` },
        {
          label: "Profile",
          icon: personFill,
          linkTo: `/${user?.screenName}`,
        },
        { label: "Settings", icon: settings2Fill, linkTo: `/account` },
      ];
    } else {
      MENU_OPTIONS = [
        { label: "Settings", icon: settings2Fill, linkTo: `/account` },
      ];
    }
  } else {
    navigate("/");
  }
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    user && (
      <>
        <MIconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              "&:before": {
                zIndex: 1,
                content: "''",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                position: "absolute",
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              },
            }),
          }}
        >
          <Avatar
            alt={user?.fullName}
            src={user?.avatarUrl ? BASE_AVATAR_URL + user?.avatarUrl : ""}
          />
        </MIconButton>

        <MenuPopover
          open={open}
          onClose={handleClose}
          anchorEl={anchorRef.current}
          sx={{ width: 220 }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle1" noWrap>
              {user?.fullName}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
              {user?.email}
            </Typography>
          </Box>

          <Divider sx={{ my: 1 }} />

          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: "body2", py: 1, px: 2.5 }}
            >
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
              />

              {option.label}
            </MenuItem>
          ))}

          <Box sx={{ p: 2, pt: 1.5 }}>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Box>
        </MenuPopover>
      </>
    )
  );
}
