import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { SOCKET_ENDPOINT } from "../../utils/constants";

const initialState = {
  isLoading: false,
  error: false,
  artworkPropertyList: [],
};

const slice = createSlice({
  name: "artworkProperty",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE ARTWORKPropertyS
    getArtworkPropertyListSuccess(state, action) {
      state.isLoading = false;
      state.artworkPropertyList = action.payload;
    },

    // CREATE ArtworkProperty
    createArtworkPropertySuccess(state, action) {
      const newItem = action.payload;
      state.isLoading = false;
      state.artworkPropertyList = [...state.artworkPropertyList, newItem];
    },

    // UPDATE ArtworkProperty
    updateArtworkPropertySuccess(state, action) {
      const item = action.payload;
      let updateItem = state.artworkPropertyList.map((data) => {
        if (data._id === item.itemId) {
          data.name = item.name;
        }
        return data;
      });

      state.isLoading = false;
      state.artworkPropertyList = updateItem;
    },

    // DELETE Artwork Category
    deleteArtworkPropertySuccess(state, action) {
      const { itemId } = action.payload;
      const deleteItem = filter(
        state.artworkPropertyList,
        (item) => item._id !== itemId,
      );
      state.isLoading = false;
      state.artworkPropertyList = deleteItem;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions

export function getArtworkPropertyList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/manage/property/get-artwork-property`,
      );
      dispatch(slice.actions.getArtworkPropertyListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// add new artwork property item
export function addArtworkPropertyItem(name, parentId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/manage/property/add-artwork-property`,
        { name: name, parentId },
      );
      dispatch(
        slice.actions.createArtworkPropertySuccess(response.data.result),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatedArtworkPropertyItem(name, itemId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/manage/property/update-artwork-property`,
        { name: name, itemId },
      );
      dispatch(slice.actions.updateArtworkPropertySuccess({ name, itemId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete artwork property item
export function deleteArtworkPropertyItem(itemId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(
        `${SOCKET_ENDPOINT}/api/manage/property/delete-artwork-property`,
        { id: itemId },
      );
      dispatch(slice.actions.deleteArtworkPropertySuccess({ itemId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
