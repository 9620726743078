import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { styled } from "@mui/material/styles";
import {
  Stack,
  Alert,
  Checkbox,
  Dialog,
  DialogContent,
  TextField,
  IconButton,
  FormControlLabel,
  InputAdornment,
  Divider,
} from "@mui/material";
import useAuth from "src/hooks/useAuth";

import {
  GoogleLogin,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import { client_id } from "src/utils/constants";
import { SOCKET_ENDPOINT } from "src/utils/constants";
import axios from "axios";
import useSettings from "src/hooks/useSettings";

import { ReactComponent as ArtistIcon } from '../../assets/img/artist.svg';

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.mode === "dark" ? "black" : "white",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.mode === "dark" ? "black" : "white",
    },
  },
}));

const JoinAsBtn = styled("button")(() => ({
  background: "#108a00", 
  outline: "none", 
  border: "none", 
  padding: "10px 24px", 
  borderRadius: "50px", 
  color: "white", 
  fontWeight: "600", 
  fontSize: "16px", 
  margin: "30px 0",
  cursor: "pointer"
}))

const VoterIcon = styled("img")({
  color: "black",
  width: "30px",
  height: "30px",
  paddingLeft: "3px"
});

export default function AccountTypeSelect ({
  accountTypeModal,
  setAccountTypeModal,
  handleLoginModal,
  handleSignUpModal,
  handleArtistModal,
}) {
  const [joinAs, setJoinAs] = useState("");

  const toggleJoin = () => {
    if(joinAs === "artist") {
      setJoinAs("voter");
    } else {
      setJoinAs("artist");
    }
  }

  return (
    <Dialog fullWidth open={accountTypeModal} onClose={() => setAccountTypeModal(false)}>
      <DialogContent>
        <MainContainerStyle>
          <TitleContainerStyle>
            <TitleStyle>
              Welcome to&nbsp;
              <span style={{ fontFamily: "ThankYouSoMuch", fontWeight: 400 }}>
                <span style={{ color: "#F30E3E" }}>A</span>rtshole
              </span>
              <span style={{ color: "#F30E3E" }}>.</span>
            </TitleStyle>
            <SubTitleStyle>Beleivers in the art.</SubTitleStyle>
          </TitleContainerStyle>

          <SubTitleStyle sx={{ mb: "20px" }}>
            Already have an account?{" "}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={handleLoginModal}
            >
              {" "}
              Sign In
            </span>
          </SubTitleStyle>
          <SubTitleStyle>
            Choose the sign-up package that best suits your needs — all accounts are free!
          </SubTitleStyle>
        <div>
            <div className={`signup-join-as ${joinAs === "artist" ? "active" : ""}`}>
                <div className="select-bar">
                    <ArtistIcon/>
                    <h4>Artist Account</h4>
                    <div className="icon-wrapper">
                        <div className="icon-content"></div>
                    </div>
                </div>
                <input onClick={() => setJoinAs("artist")}/>
                
                <ul style={{padding: "10px 20px", fontSize: "12px", textAlign: "left"}}>
                    <li>Create a Profile Page: Showcase your art by uploading images to your profile.</li>
                    <li>Participate in Voting: Have your say in our community by voting on competitions.</li>
                    <li>Post Exhibitions: Share your own exhibitions with our art-loving community.</li>
                </ul>
            </div>
            <div className={`signup-join-as ${joinAs === "voter" ? "active" : ""}`}>
                <div className="select-bar">
                    <VoterIcon src="/img/voter.png"/>
                    <h4>Member Account</h4>
                    <div className="icon-wrapper">
                        <div className="icon-content"></div>
                    </div>
                </div>
                <input onClick={() => setJoinAs("voter")}/>
                
                <ul style={{padding: "10px 20px", fontSize: "12px", textAlign: "left"}}>
                    <li>Vote: Engage with the art community by voting on your favourite artists.</li>
                    <li>Post Exhibitions: Contribute to the community by sharing exhibitions you're excited about.</li>
                </ul>
            </div>
        </div>

        <JoinAsBtn disabled={joinAs === ""} onClick={joinAs === "artist" ? handleArtistModal : handleSignUpModal}>{joinAs === "artist" ? "Join as an Artist" : "Join as a Member"}</JoinAsBtn>  

        </MainContainerStyle>
      </DialogContent>
    </Dialog>
  );
}

const MainContainerStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
}));

const TitleContainerStyle = styled("div")(({ theme }) => ({
  marginTop: "15px",
  marginBottom: "15px",
}));

const TitleStyle = styled("p")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 700,
}));

const SubTitleStyle = styled("p")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
}));
