import { useRef, useState, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { Icon } from "@iconify/react";

import {
  Box,
  Stack,
  Badge,
  Avatar,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { useSelector, useDispatch } from "react-redux";
import {
  getNotificationList,
  readNotifications,
  addNotificationItem,
  newNotificationSuccess,
} from "src/redux/slices/notification";
import { toggleFollow } from "src/redux/slices/user";

import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import NotificationsPopverItem from "./NotificationPopverItem";

import useAuth from "src/hooks/useAuth";
import { useMyContext } from "src/contexts/StateHolder";
import { useParams } from "react-router-dom";
import { useSocketContext } from "src/contexts/SocketContext";

export default function NotificationsPopverClient({ theme }) {
  const { user } = useAuth();
  const socket = useSocketContext();
  const { setIsOnline } = useMyContext();
  const dispatch = useDispatch();
  const anchorRef = useRef(null);

  const { notificationList } = useSelector((state) => state.notification);
  const { chatId, receiverId } = useParams();

  const [arrivalNotification, setArrivalNotification] = useState(null);
  const [open, setOpen] = useState(false);

  const totalUnRead = notificationList?.filter(
    (item) => item.isUnRead === true && item.toUser._id === user?._id,
  ).length;
  let unread_message_list = notificationList?.filter(
    (item) => item.isUnRead === true && item.toUser._id === user?._id,
  );

  useEffect(() => {
    socket?.emit("addUser", user?._id);
  }, [user?._id]);

  useEffect(() => {
    dispatch(getNotificationList(user?._id));
  }, [dispatch, arrivalNotification]);

  useEffect(() => {
    socket?.on("getUsers", (users) => {
      setIsOnline(users.some((u) => u.userId === receiverId));
    });
  }, [receiverId]);

  useEffect(() => {
    socket?.on("getNotification", (data) => {
      setArrivalNotification({
        senderId: data.senderId,
        receiverId: data.receiverId,
        notificationContent: data.notificationContent,
        type: data.type,
      });
    });
  }, []);

  useEffect(() => {
    arrivalNotification &&
      dispatch(newNotificationSuccess(arrivalNotification));
  }, [arrivalNotification]);

  const handleOpen = (userId) => {
    if (userId) {
      setOpen(true);
    }
  };

  const handleClose = (userId) => {
    setOpen(false);
    dispatch(readNotifications(userId));
  };

  return (
    <>
      <IconButton
        size="small"
        ref={anchorRef}
        onClick={() => handleOpen(user?._id)}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon
            icon="ant-design:notification-outlined"
            width="24"
            height="24"
            color={theme == "dark" ? "black" : "white"}
          />
        </Badge>
      </IconButton>

      {user && (
        <MenuPopover
          open={open}
          onClose={() => handleClose(user?._id)}
          anchorEl={anchorRef.current}
          sx={{ width: 450 }}
        >
          <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1">Notifications</Typography>
            </Box>

            <ViewAllStyle
              component={RouterLink}
              to="/notifications"
              onClick={() => handleClose(user?._id)}
              mode={theme}
            >
              View All
            </ViewAllStyle>
          </Box>

          <Divider />

          <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
            {totalUnRead > 0 ? (
              <Table
                sx={{ minWidth: 250 }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                <TableBody>
                  {unread_message_list.map((item, index) => {
                    return <NotificationsPopverItem key={index} item={item} />;
                  })}
                </TableBody>
              </Table>
            ) : (
              <Stack sx={{ p: "20px" }}>No new notification</Stack>
            )}
          </Scrollbar>
        </MenuPopover>
      )}
    </>
  );
}

const FollowBTNStyle = styled("button")(({ theme }) => ({
  border: "none",
  borderRadius: "4px",
  backgroundColor: "#1A82E2",
  color: "#fff",
  fontSize: "12px",
  fontFamily: "Poppins",
  padding: "6px 20px",
}));

const SubTitleStyle = styled("p")(() => ({
  fontSize: "12px",
  fontFamily: "Poppins",
  fontWeight: 400,
  margin: "10px 10px 0",
}));

const SpanTextStyle = styled("span")(() => ({
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: 600,
}));

const SpanSubTextStyle = styled("span")(() => ({
  fontSize: "12px",
  fontFamily: "Poppins",
  fontWeight: 400,
}));

const ClockSpanStyle = styled("span")(() => ({
  fontSize: "12px",
  fontFamily: "Poppins",
  display: "flex",
  alignItems: "center",
}));

const ViewAllStyle = styled(RouterLink)((mode) => ({
  fontSize: "12px",
  fontFamily: "Poppins",
  backgroundColor: "transparent",
  border: "none",
  color : mode == 'dark' ? "black" : "white"
}));
