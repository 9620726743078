import React, { useState, useEffect } from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";

import { styled } from "@mui/material/styles";
const MenuItemStyle = styled(RouterLink)(({ theme }) => ({
  fontSize: 15,
  fontWeight: 700,
  textDecoration: "none",
  cursor: "pointer",
  color: theme == "dark" ? "black" : "white",
}));

export default function PopoverMenu({ item, theme }) {
  const { pathname } = useLocation();
  const isArtists = pathname.includes("/artists");
  const isCollections = pathname.includes("/collections");
  const isAbout = pathname.includes("/about");
  const isProfile = pathname.includes("/profile");
  const isAccount = pathname.includes("/account");
  const isUpload = pathname.includes("/upload");
  const isMessage = pathname.includes("/messageboard");
  const isNotification = pathname.includes("/notifications");
  let isArtworks = false;

  if (
    !isArtists &&
    !isCollections &&
    !isAbout &&
    !isProfile &&
    !isMessage &&
    !isNotification &&
    !isAccount &&
    !isUpload
  ) {
    isArtworks = true;
  }

  return (
    <MenuItemStyle to={item.link} theme={theme}>
      {item.name}
    </MenuItemStyle>
  );
}
