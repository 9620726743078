import SvgIconStyle from "../../components/SvgIconStyle";

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

const ICONS = {
  user: getIcon("ic_user"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
};

const sidebarConfig = [
  // GENERAL

  {
    subheader: "general",
    items: [
      { title: "Dashboard", path: "/admin/dashboard", icon: ICONS.dashboard },
      { title: "Analysis", path: "/admin/analysis", icon: ICONS.analytics },
      // { title: 'Feed', path: '/admin/feed', icon: ICONS.analytics },
    ],
  },

  // MANAGEMENT

  {
    subheader: "management",
    items: [
      { 
        title: "Users", 
        path: "/admin/user", 
        icon: ICONS.user,
        children: [
          { title: "All", path: "/admin/user/all" },
          { title: "Artist", path: "/admin/user/artist" },
          { title: "Voter", path: "/admin/user/voter" },
        ],
      },
      { title: "Artworks", path: "/admin/artwork", icon: ICONS.ecommerce },
      {
        title: "Artwork Property",
        path: "/admin/property",
        icon: ICONS.ecommerce,
      },
      {
        title: "Category",
        path: "/category",
        icon: ICONS.user,
        children: [
          { title: "Artist", path: "/admin/category/artist" },
          { title: "Job", path: "/admin/category/job" },
          { title: "Artwork", path: "/admin/category/artwork" },
          { title: "Collection", path: "/admin/category/collection" },
        ],
      },
      // { title: 'Notificaiotns', path: '/admin/notification', icon: ICONS.ecommerce },
      {
        title: "Collections",
        path: "/admin/collection",
        icon: ICONS.ecommerce,
      },
      {
        title: "Vote",
        path: "/admin/vote",
        icon: ICONS.ecommerce,
      },
    ],
  },
];

export default sidebarConfig;
