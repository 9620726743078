import React, { useState } from "react";
import SignIn from "./SignIn";
import Welcome from "./Welcome";
import SignUp from "./SignUp";
import SignUpArtist from "./SignUpArtist";
import ForgotPassword from "./ForgotPassword";
import useSettings from "src/hooks/useSettings";

import AccountTypeSelect from "./AccountTypeSelect";

export default function AuthModal() {
  // const [welcomeModal, setWelcomeModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignUpModal] = useState(false);
  const [signupArtist, setSignUpArtist] = useState(false);
  const [forgot, setForgot] = useState(false);
  const [accountTypeModal, setAccountTypeModal] = useState(false);

  const { signUpShow, onChangeSignUpShow } = useSettings();

  const handleLoginModal = () => {
    setSignUpModal(false);
    setSignUpArtist(false);
    // setWelcomeModal(false);
    setForgot(false);
    setAccountTypeModal(false);

    setLoginModal(true);

    onChangeSignUpShow();
  };

  const handleSignUpModal = () => {
    // setWelcomeModal(false);
    setSignUpArtist(false);
    setLoginModal(false);
    setAccountTypeModal(false);

    onChangeSignUpShow();
  };

  const handleArtistModal = () => {
    // setWelcomeModal(false);
    setSignUpModal(false);
    setLoginModal(false);
    setAccountTypeModal(false);

    setSignUpArtist(true);
  };

  const handleForgotModal = () => {
    setLoginModal(false);
    setForgot(true);
  };

  const handleAccountTypeModal = () => {
    setLoginModal(false);
    setSignUpArtist(false);
    setSignUpModal(false);

    setAccountTypeModal(true);
  }

  // const handleWelcomeModal = () => {
  //   setLoginModal(false);
  //   setWelcomeModal(true);
  // };

  return (
    <>
      {/* <Welcome
        welcomeModal={welcomeModal}
        setWelcomeModal={setWelcomeModal}
        handleLoginModal={handleLoginModal}
        handleSignUpModal={handleSignUpModal}
        handleArtistModal={handleArtistModal}
      /> */}
      <SignIn
        loginModal={loginModal}
        setLoginModal={setLoginModal}
        handleSignUpModal={handleArtistModal}
        // handleWelcomeModal={handleWelcomeModal}
        handleAccountTypeModal = {handleAccountTypeModal}
        handleForgotModal={handleForgotModal}
      />
      {/* <SignUp
        signupModal={signupModal}
        setSignUpModal={setSignUpModal}
        handleLoginModal={handleLoginModal}
        otherPlan = {false}
        handleArtistModal={handleArtistModal}
      /> */}
      <SignUp
        signupModal={signUpShow}
        setSignUpModal={onChangeSignUpShow}
        handleLoginModal={handleLoginModal}
        otherPlan = {false}
        handleArtistModal={handleArtistModal}
      />
      <AccountTypeSelect
        accountTypeModal={accountTypeModal}
        setAccountTypeModal={setAccountTypeModal}
        handleLoginModal={handleLoginModal}
        handleSignUpModal = {handleSignUpModal}
        handleArtistModal={handleArtistModal}
      />
      <SignUpArtist
        signupArtist={signupArtist}
        setSignUpArtist={setSignUpArtist}
        handleLoginModal={handleLoginModal}
        handleSignUpModal={handleSignUpModal}
      />
      <ForgotPassword
        forgot={forgot}
        setForgot={setForgot}
        handleLoginModal={handleLoginModal}
      />
    </>
  );
}
