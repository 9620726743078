import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { makeStyles } from "@mui/styles";
import {
  Divider,
  Grid,
  Typography,
  Stack,
  IconButton,
  InputBase,
  useMediaQuery,
  Box,
  Button,
} from "@mui/material";
import useSettings from "src/hooks/useSettings";
import FooterLogoIcon from "src/components/icons/FooterLogoIcon";

const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "100px",
  width: "100%",
  padding: "0px 0px 40px 0px",
  // background: "#F30E3E",
}));

const MainContainerStyle = styled("div")(({ theme }) => ({
  width: "100%",
  // maxWidth: '1500px',
  padding: "0px 15px",
  margin: "0px auto",
  position: "relative",
  [theme.breakpoints.up("sm")]: {
    padding: "0px 30px",
    flexDirection: "row",
  },
  // [theme.breakpoints.up('md')]: {
  //   width: '80%',
  //   padding: '0px'
  // }
}));

const StackStyle = styled("div")(({ theme, mode }) => ({
  position: "relative",
  transform: "translate(34%, -50%)",
  border: "1px solid",
  padding: "40px 0",
  borderRadius: "8px",
  borderColor: mode == "dark" ? "#DEDEDE" : "#F30E3E",
  background: mode == "dark" ? "white" : "#060415",
  width: "60%",
  [theme.breakpoints.up("xs")]: {
    transform: "translate(0, -50%)",
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    transform: "translate(34%, -50%)",
    width: "60%",
  },
  [theme.breakpoints.up("sm")]: {
    transform: "translate(0%, -50%)",
    width: "100%",
  },
  [theme.breakpoints.up("xl")]: {
    transform: "translate(34%, -50%)",
    width: "60%",
  },
}));

const TitleInputStyle = styled("div")(({ theme }) => ({
  flex: 1,
  margin: "0px 15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  flexDirection: "row",
  [theme.breakpoints.up("xs")]: {
    flexDirection: "column",
    margin: "0px",
  },
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}));

const TitleStyle = styled("p")(({ theme }) => ({
  fontSize: 18,
  fontWeight: "600",
  [theme.breakpoints.up("sm")]: {
    marginRight: 25,
    fontSize: 20,
  },
}));

const InputWrapperStyle = styled("div")(({ theme }) => ({
  backgroundColor: "#EDEFF1",
  overFlow: "hidden",
  borderRadius: 5,
  flex: 1,
  color: "black",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("xs")]: {
    marginTop: 0,
    maxWidth: 300,
  },
  [theme.breakpoints.up("sm")]: {
    maxWidth: 500,
  },
  [theme.breakpoints.up("md")]: {
    marginTop: 0,
    maxWidth: 650,
  },
}));

const SubscribeBtnStyle = styled(Button)(({ theme }) => ({
  marginLeft: "-100px",
  background: "linear-gradient(0deg, #F30E3E 0%, #F30E3E 100%)",
  color: "black",
  width: 100,
  height: 39,
  fontSize: 12,
  fontWeight: "500",
  textTransform: "capitalize",
  border: "none",
  borderRadius: "5px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  "&:hover": {
    background: "#9C0021",
  },
}));

const FooterLogoImgStyle = styled("img")(({ theme }) => ({
  width: "100%",
  maxWidth: 300,
}));

const MenuTitleStyle = styled("p")(({ theme }) => ({
  fontSize: 20,
  fontWeight: "700",
  marginBottom: 21,
  marginTop: 30,
  lineHeight: "22px",
  color: "black",
}));

const MenuStyle = styled("a")(({ theme }) => ({
  display: "block",
  fontSize: 12,
  fontWeight: "500",
  marginBottom: 4,
  cursor: "pointer",
  color: "black",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const FooterSectionStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "space-between",
  "& > div, & > p": {
    marginTop: 30,
  },
}));

export default function MainFooter() {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { themeMode } = useSettings();

  return (
    <RootStyle>
      <MainContainerStyle>
        <StackStyle mode={themeMode}>
          <Stack
            direction={{ xs: "column", sm: "column", md: "row" }}
            justifyContent="space-between"
            alignItems="center"
            theme={themeMode}
          >
            <TitleInputStyle>
              <TitleStyle>Join Our Newsletter</TitleStyle>

              <Stack direction="row">
                <InputWrapperStyle>
                  <InputBase
                    placeholder="Email address"
                    className={classes.input}
                    xs={{
                      with: "200px",
                    }}
                    sx={{
                      color: "black",
                      width: "400px",
                    }}
                  />
                </InputWrapperStyle>
                <SubscribeBtnStyle>Subscribe</SubscribeBtnStyle>
              </Stack>
            </TitleInputStyle>
          </Stack>
        </StackStyle>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box component={RouterLink} to="/">
              <FooterLogoIcon />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MenuTitleStyle>For Buyers</MenuTitleStyle>
            <MenuStyle>Art Advisory Services</MenuStyle>
            <MenuStyle>Buyer FAQs</MenuStyle>
            <MenuStyle>Return Policy</MenuStyle>
            <MenuStyle>Testimonials</MenuStyle>
            <MenuStyle>Art Prints</MenuStyle>
            <MenuStyle>Catalog</MenuStyle>
            <MenuStyle>Refer a friend</MenuStyle>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MenuTitleStyle>For Artists</MenuTitleStyle>
            <MenuStyle>Why Sell</MenuStyle>
            <MenuStyle>Artist Handbook</MenuStyle>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MenuTitleStyle>Artshole</MenuTitleStyle>
            <MenuStyle href="/upload">Collections</MenuStyle>
            <MenuStyle href="/about">About Us</MenuStyle>
            <MenuStyle>Contact Us</MenuStyle>
            <MenuStyle>Terms Of Service</MenuStyle>
            <MenuStyle>Privacy Policy</MenuStyle>
          </Grid>
        </Grid>
        <Divider sx={{ mt: "30px" }} />
        <FooterSectionStyle>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography            
              sx={{ fontSize: "12px", fontWeight: 500 }}
            >
              Follow Us
            </Typography>
            <IconButton size="medium" aria-label="instagram">
              <Icon icon="akar-icons:instagram-fill" />
            </IconButton>
            <IconButton size="medium" aria-label="twitter">
              <Icon icon="akar-icons:twitter-fill" />
            </IconButton>
            <IconButton size="medium" aria-label="pinterest">
              <Icon icon="akar-icons:pinterest-fill" />
            </IconButton>
            <IconButton size="medium" aria-label="linkedin">
              <Icon icon="akar-icons:linkedin-fill" />
            </IconButton>
          </Stack>
          <Typography sx={{ fontSize: "14px" }}>
            © {new Date().getFullYear()} Artshole
          </Typography>
        </FooterSectionStyle>
      </MainContainerStyle>
    </RootStyle>
  );
}

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: 12,
    flex: 1,
    padding: "6px 14px",
    minWidth: "100px",
  },
}));
