import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { SOCKET_ENDPOINT } from "../../utils/constants";

const initialState = {
  isLoading: false,
  error: false,
  chatList: [],
  newChat: null,
};

const slice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Chats
    getChatListSuccess(state, action) {
      state.isLoading = false;
      state.chatList = action.payload;
    },

    // GET Chat
    newChatSuccess(state, action) {
      state.isLoading = false;
      state.newChat = action.payload;
      // state.chatList.push(action.payload)
    },

    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const conversation = action.payload;
      const {
        conversationId,
        messageId,
        message,
        contentType,
        attachments,
        createdAt,
        senderId,
      } = conversation;

      const newMessage = {
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
      };

      state.conversations.byId[conversationId].messages.push(newMessage);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onSendMessage, newChatSuccess } = slice.actions;

export function getAllChats(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/chat/get-chats/${userId}`,
      );
      dispatch(slice.actions.getChatListSuccess(response.data.chatList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addNewChat(senderId, receiverId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const config = { header: { "Content-Type": "application/json" } };
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/chat/new-chat`,
        { senderId, receiverId },
        config,
      );
      dispatch(slice.actions.newChatSuccess(response.data.newChat));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
