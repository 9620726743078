import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { SOCKET_ENDPOINT } from "../../utils/constants";

const initialState = {
  isLoading: false,
  error: false,
  artworkList: [],
  suggestedArtworkList: [],
  hasMore: true,
  index: 0,
  step: 8,
};

const slice = createSlice({
  name: "artwork",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE ARTWORKS
    getArtworkListSuccess(state, action) {
      state.isLoading = false;
      state.artworkList = action.payload;
    },

    // CREATE Artwork
    createArtworkSuccess(state, action) {
      const newItem = action.payload;
      state.isLoading = false;
      state.artworkList = [...state.artworkList, newItem];
    },

    // GET Artwork list INFINITE
    getArtworksInitialSuccess(state, action) {
      state.isLoading = false;
      state.artworkList = action.payload;
    },

    getMoreArtworks(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // DELETE ARTWORK
    deleteArtworkSuccess(state, action) {
      const { itemId } = action.payload;
      const deleteItem = filter(
        state.artworkList,
        (item) => item._id !== itemId,
      );
      state.isLoading = false;
      state.artworkList = deleteItem;
    },

    // UPDATE Artwork
    updateArtworkSuccess(state, action) {
      const item = action.payload;
      const updateItem = map(state.artworkList, (e) => {
        if (e._id === item._id) {
          return item;
        }
        return e;
      });

      state.isLoading = false;
      state.artworkList = updateItem;
    },

    updateArtworkCommentSuccess(state, newComment) {
      const itemId = newComment.payload.artworkId;
      const updateItem = map(state.artworkList, (e) => {
        if (e._id === itemId) {
          let newCommentList = e.comments.concat(newComment.payload);
          return {...e, comments: newCommentList};
        }
        return e;
      });

      state.isLoading = false;
      state.artworkList = updateItem;
    },

    // increase view count
    addViewCountArtworkSuccess(state, action) {
      const { artworkId, userId } = action.payload;
      const updateItem = map(state.artworkList, (e) => {
        if (e._id === artworkId) {
          e.viewed.push(userId);
          return e;
        }
        return e;
      });

      state.isLoading = false;
      state.artworkList = updateItem;
    },

    // toggle like
    toggleLikeSuccess(state, action) {
      const { artworkId, userId, artwork } = action.payload;

      const updateItem = map(state.artworkList, (e) => {
        if (e._id === artworkId) {
          return artwork;
        }
        return e;
      });

      state.isLoading = false;
      state.artworkList = updateItem;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreArtworks } = slice.actions;

//=====For Admin========

export function getArtworkList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/manage/artwork/get-artwork`,
      );
      dispatch(slice.actions.getArtworkListSuccess(response.data.artworkList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//=====For Client========

export function getArtworkListClient() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/artwork/get-artworks`,
      );
      dispatch(slice.actions.getArtworkListSuccess(response.data.artworkList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getArtworksInitialByUser(index, step, userId, searchObject) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/artwork/initial`,
        { index, step, userId, searchObject },
      );
      const results = response.data.results.length;
      const { maxLength } = response.data;

      dispatch(slice.actions.getArtworksInitialSuccess(response.data.results));

      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// add new artwork
export function addArtwork(formData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/artwork/add-artwork`,
        formData,
      );
      dispatch(slice.actions.createArtworkSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// update artwork
export function updateArtwork(formData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/artwork/update-artwork`,
        formData,
      );
      dispatch(slice.actions.updateArtworkSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateArtworkComment(newComment) {
  return (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.updateArtworkCommentSuccess(newComment));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete artwork
export function deleteArtwork(itemId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/artwork/delete-artwork`,
        { itemId },
      );
      dispatch(slice.actions.deleteArtworkSuccess({ itemId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addViewCountArtwork(artworkId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${SOCKET_ENDPOINT}/api/artwork/view`, {
        artworkId,
        userId,
      });
      dispatch(slice.actions.addViewCountArtworkSuccess({ artworkId, userId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// toggle like
export function toggleLike(userId, artworkId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${SOCKET_ENDPOINT}/api/artwork/like`, {
        userId,
        artworkId,
      });
      dispatch(slice.actions.toggleLikeSuccess({
        userId: userId,
        artworkId: artworkId,
        artwork: response.data.artwork
      }))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// vote up
export function VoteUp(userId, artworkId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${SOCKET_ENDPOINT}/api/artwork/voteup`, {
        userId,
        artworkId,
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
