import React, { useState, useContext, createContext } from "react";

// First create the context
const MyContext = createContext();

export default function StateHolder({ children }) {
  // show only available artworks
  const [artStatusSearch, setArtStatusSearch] = useState({
    available: false,
    sold: false,
  });

  // show category list for artwork
  const [toggleCategory, setToggleCategory] = useState(false);

  // handle category for artworks.
  const [category, setCategory] = useState("All");

  // handle property for artworks
  const [property, setProperty] = useState([]);

  // handle price range for artworks
  const [priceRange, setPriceRange] = useState([0, 10000]);

  // search artwork
  const [searchText, setSearchText] = useState("");

  // Edit Artwork
  const [editOpen, setEditOpen] = useState(false);

  // Current Artwork
  const [currentArt, setCurrentArt] = useState(null);

  //verify status
  const [verify, setVerify] = useState();

  //user online status
  const [isOnline, setIsOnline] = useState(false);

  // typing status
  const [isTyping, setIsTyping] = useState(false);

  return (
    <MyContext.Provider
      value={{
        artStatusSearch,
        setArtStatusSearch,
        toggleCategory,
        setToggleCategory,
        category,
        setCategory,
        priceRange,
        setPriceRange,
        property,
        setProperty,
        searchText,
        setSearchText,
        editOpen,
        setEditOpen,
        currentArt,
        setCurrentArt,
        verify,
        setVerify,
        isOnline,
        setIsOnline,
        isTyping,
        setIsTyping,
      }}
    >
      {children}
    </MyContext.Provider>
  );
}

export const useMyContext = () => useContext(MyContext);
