import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { SOCKET_ENDPOINT } from "../../utils/constants";

const initialState = {
  isLoading: false,
  error: false,
  collectionCategoryList: [],
};

const slice = createSlice({
  name: "collectionCategory",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE CollectionCategory
    getCollectionCategoryListSuccess(state, action) {
      state.isLoading = false;
      state.collectionCategoryList = action.payload;
    },

    // CREATE CollectionCategory
    createCollectionCategorySuccess(state, action) {
      const newItem = action.payload;
      state.isLoading = false;
      state.collectionCategoryList = [...state.collectionCategoryList, newItem];
    },

    // UPDATE CollectionCategory
    updateCollectionCategorySuccess(state, action) {
      const item = action.payload;
      const updateItem = map(state.collectionCategoryList, (e) => {
        if (e._id === item._id) {
          return item;
        }
        return e;
      });

      state.isLoading = false;
      state.collectionCategoryList = updateItem;
    },

    // DELETE CollectionCategory
    deleteCollectionCategorySuccess(state, action) {
      const { itemId } = action.payload;
      const deleteItem = filter(
        state.collectionCategoryList,
        (item) => item._id !== itemId,
      );
      state.isLoading = false;
      state.collectionCategoryList = deleteItem;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions

export function getCollectionCategoryList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/manage/category/get-collection-category`,
      );
      dispatch(slice.actions.getCollectionCategoryListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// add new collection category item
export function addCollectionCategoryItem(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/manage/category/add-collection-category`,
        { name },
      );
      dispatch(
        slice.actions.createCollectionCategorySuccess(response.data.result),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete user data
export function deleteCollectionCategoryItem(itemId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(
        `${SOCKET_ENDPOINT}/api/manage/category/delete-collection-category`,
        { id: itemId },
      );
      dispatch(slice.actions.deleteCollectionCategorySuccess({ itemId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
