import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

const ContainerStyle = styled("div")(({ theme }) => ({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "25px",
}));

const Loadable = (Component) => (props) => {
  return (
    <Suspense
      fallback={
        <ContainerStyle>
          <CircularProgress color="clientprimary" size="100px" />
        </ContainerStyle>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/admin",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/admin/dashboard" replace /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "analysis", element: <Analysis /> },
        { 
          path: "user", 
          // element: <UserManagementPage />,
          children: [
            { path: "all", element: <UserManagementPage /> },
            { path: "artist", element: <ArtistManagementPage /> },
            { path: "voter", element: <VoterManagementPage /> },
          ],        
        },
        { path: "artwork", element: <ArtworkManagePage /> },
        { path: "property", element: <ArtworkPropertyPage /> },
        { path: "property/:itemId", element: <ArtworkPropertyPage /> },
        {
          path: "category",
          children: [
            { element: <Navigate to="/dashboard/category/artwork" replace /> },
            { path: "artist", element: <CategoryArtistPage /> },
            { path: "job", element: <CategoryJobPage /> },
            { path: "artwork", element: <CategoryArtworkPage /> },
            { path: "collection", element: <CategoryCollectionPage /> },
          ],
        },
        { path: "notification", element: <NotificationManagePage /> },
        { path: "collection", element: <CollectionManagePage /> },
        { path: "collection/:id", element: <IndividualCollection /> },
        { path: "vote", element: <VoteManagementPage /> },
        { path: "vote/:id", element: <VoteDetailPage /> },
      ],
    },

    {
      path: "*",
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/about",
      element: <MainLayout />,
      children: [{ element: <LandingPage /> }],
    },
    {
      path: "/vote",
      element: <MainLayout />,
      children: [{ element: <VotePage /> }],
    },
    {
      path: "/exhibition",
      element: <MainLayout />,
      children: [{ element: <ExhibitionPage /> }],
    },
    {
      path: "/exhibition/:id",
      element: <MainLayout />,
      children: [{ element: <ExhibitionOnePage /> }],
    },
    {
      path: "/event/find-venue",
      element: <MainLayout />,
      children: [{ element: <AddEventPage /> }],
    },
    {
      path: "/events",
      element: <MainLayout />,
      children: [{ element: <AllEventsPage /> }],
    },
    {
      path: "/events/:id",
      element: <MainLayout />,
      children: [{ element: <EventDetailPage /> }]
    },
    {
      path: "/online",
      element: <MainLayout />,
      children: [{ element: <OnlineEventsPage /> }]
    },
    {
      path: "/artMap",
      element: <MainLayout />,
      children: [{ element: <ArtMapPage /> }]
    },
    {
      path: "/organizations/add-organization",
      element: <MainLayout />,
      children: [{ element: <AddOrganizationPage /> }]
    },
    {
      path: "/organisations",
      element: <MainLayout />,
      children: [{ element: <AllOrganisationsPage /> }]
    },
    {
      path: "/organisations/:id",
      element: <MainLayout />,
      children: [{ element: <OrganisationDetailPage/> }]
    },
    {
      path: "/artist-opportunities",
      element: <MainLayout />,
      children: [{ element: <OpenCallPage/> }]
    },
    {
      path: "/password/forgot/success/:email",
      children: [{ element: <ForgotPasswordSuccess /> }],
    },
    {
      path: "/password/reset/:token/:email",
      children: [{ element: <ResetPasswordPage /> }],
    },
    {
      path: "/password/reset/success",
      element: <MainLayout />,
      children: [{ element: <ResetPasswordSuccess /> }],
    },
    {
      path: "/signup/email-verify/",
      children: [{ element: <EmailVerifyPage /> }],
    },
    {
      path: "/signup/email-verify/:token",
      children: [{ element: <EmailVerifyPage /> }],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [{ element: <ArtworksPage /> }],
    },
    {
      path: "/search",
      element: <MainLayout />,
      children: [{ element: <SearchPage /> }],
    },
    {
      path: "/artists",
      element: <MainLayout />,
      children: [{ element: <ArtistsPage /> }],
    },
    {
      path: "/collections",
      element: <MainLayout />,
      children: [{ element: <CollectionsPage /> }],
    },
    {
      path: "collections/:id",
      element: <MainLayout />,
      children: [{ element: <CollectionOnePage /> }],
    },
    {
      path: "/:screenName",
      element: <MainLayout />,
      children: [{ element: <ProfilePage /> }],
    },
    {
      path: "/account",
      element: <MainLayout />,
      children: [{ element: <AccountPage /> }],
    },
    {
      path: "/upload",
      element: <MainLayout />,
      children: [{ element: <UploadPage /> }],
    },
    {
      path: "/messageboard/inbox",
      element: <MainLayout />,
      children: [{ element: <MessageBoard /> }],
    },
    {
      path: "/messageboard/:chatId/:receiverId",
      element: <MainLayout />,
      children: [{ element: <MessageBoard /> }],
    },

    {
      path: "notifications",
      element: <MainLayout />,
      children: [{ element: <NotificationPage /> }],
    },
  ]);
}

const Dashboard = Loadable(
  lazy(() => import("../pages/administrator/Dashboard")),
);
const Analysis = Loadable(
  lazy(() => import("../pages/administrator/Analysis")),
);
const CategoryJobPage = Loadable(
  lazy(() => import("../pages/administrator/CategoryJobPage")),
);
const CategoryArtistPage = Loadable(
  lazy(() => import("../pages/administrator/CategoryArtistPage")),
);
const CategoryArtworkPage = Loadable(
  lazy(() => import("../pages/administrator/CategoryArtworkPage")),
);
const ArtworkPropertyPage = Loadable(
  lazy(() => import("../pages/administrator/ArtworkPropertyPage")),
);
const CategoryCollectionPage = Loadable(
  lazy(() => import("../pages/administrator/CategoryCollectionPage")),
);
const VoteManagementPage = Loadable(
  lazy(() => import("../pages/administrator/VoteManagementPage")),
);
const VoteDetailPage = Loadable(
  lazy(() => import("../pages/administrator/VoteDetailPage")),
);
const UserManagementPage = Loadable(
  lazy(() => import("../pages/administrator/UserManagementPage")),
);
const ArtistManagementPage = Loadable(
  lazy(() => import("../pages/administrator/ArtistManagementPage")),
);
const VoterManagementPage = Loadable(
  lazy(() => import("../pages/administrator/VoterManagementPage")),
);
const ArtworkManagePage = Loadable(
  lazy(() => import("../pages/administrator/ArtworkManagePage")),
);
const NotificationManagePage = Loadable(
  lazy(() => import("../pages/administrator/NotificationManagePage")),
);
const CollectionManagePage = Loadable(
  lazy(() => import("../pages/administrator/CollectionManagePage")),
);
const IndividualCollection = Loadable(
  lazy(() => import("../pages/administrator/IndividualCollection")),
);
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
const ResetPasswordPage = Loadable(
  lazy(() => import("../pages/auth/ResetPasswordPage")),
);
const ResetPasswordSuccess = Loadable(
  lazy(() => import("../pages/auth/ResetPasswordSuccess")),
);
const ForgotPasswordSuccess = Loadable(
  lazy(() => import("../pages/auth/ForgotPasswordSuccess")),
);
const EmailVerifyPage = Loadable(
  lazy(() => import("../pages/auth/EmailVerifyPage")),
);

const LandingPage = Loadable(lazy(() => import("../pages/LandingPage")));
const VotePage = Loadable(lazy(() => import("../pages/client/VotePage")));
const ExhibitionPage = Loadable(lazy(() => import("../pages/client/ExhibitionPage")));
const ExhibitionOnePage = Loadable(lazy(() => import("../pages/client/ExhibitionOnePage")));
const AddEventPage = Loadable(lazy(() => import("../pages/client/Event/AddEventPage")));
const AllEventsPage = Loadable(lazy(() => import("../pages/client/Event/AllEventsPage")));
const EventDetailPage = Loadable(lazy(() => import("../pages/client/Event/EventDetailPage")));
const OnlineEventsPage = Loadable(lazy(() => import("../pages/client/Event/OnlineEventsPage")));
const AddOrganizationPage = Loadable(lazy(() => import("../pages/client/organization/AddOrganizationPage")));
const AllOrganisationsPage = Loadable(lazy(() => import("../pages/client/organization/AllOrganisationsPage")));
const OrganisationDetailPage = Loadable(lazy(() => import("../pages/client/organization/OrganisationDetailPage")));
const OpenCallPage = Loadable(lazy(() => import("../pages/client/OpenCallPage")));

const ArtMapPage = Loadable(lazy(() => import("../pages/client/Event/ArtMapPage")));

const ArtworksPage = Loadable(
  lazy(() => import("../pages/client/ArtworksPage")),
);
const SearchPage = Loadable(
  lazy(() => import("../pages/client/SearchPage")),
);
const ArtistsPage = Loadable(lazy(() => import("../pages/client/ArtistsPage")));
const CollectionsPage = Loadable(
  lazy(() => import("../pages/client/CollectionsPage")),
);
const CollectionOnePage = Loadable(
  lazy(() => import("../pages/client/CollectionOnePage")),
);
const ProfilePage = Loadable(lazy(() => import("../pages/client/ProfilePage")));
const AccountPage = Loadable(lazy(() => import("../pages/client/AccountPage")));
const UploadPage = Loadable(lazy(() => import("../pages/client/UploadPage")));
const MessageBoard = Loadable(
  lazy(() => import("../pages/client/MessageBoard")),
);
const NotificationPage = Loadable(
  lazy(() => import("../pages/client/NotificationPage")),
);
