import { Icon } from "@iconify/react";
import { styled } from "@mui/material/styles";
import {
  Stack,
  Dialog,
  DialogContent,
  IconButton,
  Divider,
} from "@mui/material";

const MainContainerStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "auto",
  width: "fit-content",
  textAlign: "center",
}));

const TitleContainerStyle = styled("div")(({ theme }) => ({
  marginBottom: "20px",
}));

const TitleStyle = styled("p")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 700,
}));

const SubTitleStyle = styled("p")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
}));

const ContentTitleStyle = styled("p")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: 600,
}));

const ButtonStyle = styled("button")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 500,
  borderRadius: "5px",
  border: "1px solid #000",
  backgroundColor: "#fff",
  padding: "10px 50px",
  cursor: "pointer",
}));

const DividerStyle = styled("div")(({ theme }) => ({
  width: "80%",
  height: "1px",
  backgroundColor: "#7C7C7C",
  margin: "40px 0 20px",
}));

export default function Welcome({
  welcomeModal,
  setWelcomeModal,
  handleLoginModal,
  handleClientModal,
  handleArtistModal,
}) {
  return (
    <>
      <IconButton size="small" onClick={() => setWelcomeModal(true)}>
        <Icon
          icon="ant-design:user-outlined"
          color="#000"
          width="24"
          height="24"
        />
      </IconButton>
      <Dialog
        maxWidth="md"
        open={welcomeModal}
        onClose={() => setWelcomeModal(false)}
      >
        <DialogContent>
          <MainContainerStyle>
            <TitleContainerStyle>
              <TitleStyle>
                Welcome to&nbsp;
                <span style={{ fontFamily: "ThankYouSoMuch", fontWeight: 400 }}>
                  <span style={{ color: "#F30E3E" }}>A</span>rtshole
                </span>
                <span style={{ color: "#F30E3E" }}>.</span>
              </TitleStyle>
              <SubTitleStyle>Beleivers in the art.</SubTitleStyle>
            </TitleContainerStyle>
            {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2.5}> */}
            {/* <Stack alignItems="center" spacing={3} sx={{ px: '6%' }}>
              <Icon icon="ant-design:user-outlined" color="#000" width="50" height="50" />
              <ContentTitleStyle>I am an artist</ContentTitleStyle>
              <SubTitleStyle sx={{ fontWeight: 500 }}>
                Create an account to publish or sell your artworks.
              </SubTitleStyle>
              <ButtonStyle onClick={handleArtistModal}>Create an account</ButtonStyle>
            </Stack> */}

            {/* <Divider orientation="vertical" variant="middle" flexItem sx={{ borderColor: '#7C7C7C' }} /> */}

            <Stack alignItems="center" spacing={3} sx={{ px: "6%" }}>
              <Icon icon="ant-design:user-outlined" width="50" height="50" />
              <ContentTitleStyle>I am an art lover, a buyer</ContentTitleStyle>
              <SubTitleStyle sx={{ fontWeight: 500 }}>
                Create an account to save or buy your favorite artworks.
              </SubTitleStyle>
              <ButtonStyle onClick={handleClientModal}>
                Create an account
              </ButtonStyle>
            </Stack>

            {/* </Stack> */}

            <DividerStyle />

            <SubTitleStyle>
              Already have an account?{" "}
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={handleLoginModal}
              >
                Sign in
              </span>
            </SubTitleStyle>
          </MainContainerStyle>
        </DialogContent>
      </Dialog>
    </>
  );
}
