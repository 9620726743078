import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import axios from "../../utils/axios";
import { SOCKET_ENDPOINT } from "../../utils/constants";

import { updateArtworkComment } from "./artwork";

const initialState = {
  isLoading: false,
  error: false,
  commentList: [],
};

const slice = createSlice({
  name: "comment",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE Comments
    getCommentListSuccess(state, action) {
      state.isLoading = false;
      state.commentList = action.payload;
    },

    // CREATE Comment
    createCommentSuccess(state, action) {
      const newItem = action.payload;
      state.isLoading = false;
      state.commentList = [...state.commentList, newItem];
    },

    // DELETE Comment
    deleteComment(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions

// export function getCommentList(comment_id_list) {
//     return async (dispatch) => {
//         dispatch(slice.actions.startLoading());
//         try {
//             const response = await axios.post(
//                 `${SOCKET_ENDPOINT}/api/manage/comment/get-comment`,
//                 { comment_id_list }
//             );

//             dispatch(slice.actions.getCommentListSuccess(respons e.data));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// add new comment
export function addComment(item) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/comment/add-comment`,
        item,
      );
      dispatch(slice.actions.createCommentSuccess(response.data.result));
      dispatch(updateArtworkComment(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
