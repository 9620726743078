import { useEffect } from "react";
import Router from "./routes";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import "./index.css";
import ScrollToTop from "./components/ScrollToTop";
import { useLocation } from 'react-router-dom';
import useSettings from "./hooks/useSettings";

import { GoogleMapsProvider } from "./contexts/GoogleMapContext";

import useAuth from "./hooks/useAuth";
const ContainerStyle = styled("div")(({ theme }) => ({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "25px",
}));

export default function App() {
  const { isInitialized } = useAuth();
  const { onChangeSearch } = useSettings();

  const location = useLocation();

  useEffect(() => {
    onChangeSearch("");
  }, [location]);

  return (
    <ThemeConfig>
      <GlobalStyles />
      <ScrollToTop />
      {isInitialized ? (
        <GoogleMapsProvider>
          <Router />
        </GoogleMapsProvider>
      ) : (
        <ContainerStyle>
          <CircularProgress color="clientprimary" size="100px" />
        </ContainerStyle>
      )}
    </ThemeConfig>
  );
}
