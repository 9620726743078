import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { SOCKET_ENDPOINT } from "../../utils/constants";

const initialState = {
  isLoading: false,
  error: false,
  collectionList: [],
  oneCollection: null,
  hasMore: true,
  index: 0,
  step: 8,
};

const slice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Collections
    getCollectionSuccess(state, action) {
      state.isLoading = false;
      state.collectionList = action.payload;
    },

    // CREATE Collection
    createCollectionSuccess(state, action) {
      const newItem = action.payload;
      state.isLoading = false;
      state.collectionList = [...state.collectionList, newItem];
    },

    // GET Collections INFINITE
    getCollectionsInitialSuccess(state, action) {
      state.isLoading = false;
      state.collectionList = action.payload;
    },

    getMoreCollections(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET one collection by id
    getOneCollectionSuccess(state, action) {
      state.isLoading = false;
      state.oneCollection = action.payload;
    },

    // DELETE Collection
    deleteCollectionSuccess(state, action) {
      const { itemId } = action.payload;
      const deleteItem = filter(
        state.collectionList,
        (item) => item._id !== itemId,
      );
      state.isLoading = false;
      state.collectionList = deleteItem;
    },

    // increase view count
    addViewCountCollectionSuccess(state, action) {
      const { collectionId, userId } = action.payload;
      const updateItem = map(state.collectionList, (e) => {
        if (e._id === collectionId) {
          e.viewed.push(userId);
          return e;
        }
        return e;
      });

      state.isLoading = false;
      state.collectionList = updateItem;
    },

    // UPDATE artwork list on collection
    updateArtworkOnCollectionSuccess(state, action) {
      const item = action.payload;
      const updateItem = map(state.collectionList, (e) => {
        if (e._id === item._id) {
          return item;
        }
        return e;
      });

      state.isLoading = false;
      state.collectionList = updateItem;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreCollections } = slice.actions;

//========For Client======

export function getCollectionListClient() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/collection/get-collections`,
      );
      dispatch(
        slice.actions.getCollectionSuccess(response.data.collectionList),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// add new artwork
export function addCollection(formData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/collection/add-collection`,
        formData,
      );

      dispatch(slice.actions.createCollectionSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCollectionsInitialByUser(index, step, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/collection/initial`,
        { index, step, userId },
      );
      const results = response.data.results.length;
      const { maxLength } = response.data;

      dispatch(
        slice.actions.getCollectionsInitialSuccess(response.data.results),
      );

      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCollectionDetailsById(collectionId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/collection/get-onecollection`,
        { collectionId },
      );
      dispatch(
        slice.actions.getOneCollectionSuccess(response.data.oneCollection),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete collection
export function deleteCollection(itemId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${SOCKET_ENDPOINT}/api/collection/delete-collection`, {
        itemId,
      });
      dispatch(slice.actions.deleteCollectionSuccess({ itemId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSearchCollectionList(filterName) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/collection/search?keyword=${filterName}`,
      );
      dispatch(
        slice.actions.getCollectionSuccess(response.data.collectionList),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addViewCountCollection(collectionId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/collection/view`,
        { collectionId, userId },
      );
      dispatch(
        slice.actions.addViewCountCollectionSuccess({ collectionId, userId }),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMyCollections(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/collection/my-collections`,
        { userId },
      );
      dispatch(slice.actions.getCollectionSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateArtworkOnCollection(collectionId, artworkId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/collection/update-artwork`,
        { collectionId, artworkId },
      );
      dispatch(
        slice.actions.updateArtworkOnCollectionSuccess(response.data.result),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
