import "simplebar/src/simplebar.css";
import ReactDOM from "react-dom";
import { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";
import { AuthProvider } from "./contexts/JWTContext";
import NotistackProvider from "./components/NotistackProvider";
import StateHolder from "./contexts/StateHolder";
import SocketContextProvider from "./contexts/SocketContext";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { client_id } from "./utils/constants";
import { SettingsProvider } from "./contexts/SettingsContext";
ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <SocketContextProvider>
        <SettingsProvider>
          <NotistackProvider>
            <HelmetProvider>
              <CollapseDrawerProvider>
                <StateHolder>
                  <AuthProvider>
                    <GoogleOAuthProvider clientId={client_id}>
                      <BrowserRouter>
                        <App />
                      </BrowserRouter>
                    </GoogleOAuthProvider>
                  </AuthProvider>
                </StateHolder>
              </CollapseDrawerProvider>
            </HelmetProvider>
          </NotistackProvider>
        </SettingsProvider>
      </SocketContextProvider>
    </Provider>
  </StrictMode>,
  document.getElementById("root"),
);

serviceWorkerRegistration.unregister();
reportWebVitals();
