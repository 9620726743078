import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import axios from "../../utils/axios";
import { SOCKET_ENDPOINT } from "../../utils/constants";

const initialState = {
  isLoading: false,
  error: false,
  userList: [],
  artistList: [],
  oneUser: null,
  hasMore: true,
  index: 0,
  step: 8,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    // GET userList INFINITE
    getUserListInitial(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    // GET artistList for organisation create
    getArtistListSuccess(state, action) {
      state.isLoading = false;
      state.artistList = action.payload;
    },

    // GET one user by id
    getOneUserSuccess(state, action) {
      state.isLoading = false;
      state.oneUser = action.payload;
    },

    getMoreUserList(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // DELETE USERS
    deleteOneUser(state, action) {
      const deleteUser = filter(
        state.userList,
        (user) => user._id !== action.payload,
      );
      state.userList = deleteUser;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreUserList } = slice.actions;

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/manage/user/manage-users`,
      );
      dispatch(slice.actions.getUserListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete user data
export function deleteUser(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/manage/user/delete-user`,
        { userId },
      );
      dispatch(slice.actions.deleteOneUser(userId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//client

//get artist
export function getAllArtists() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/user/manage-artists`,
      );

      dispatch(slice.actions.getUserListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserListInitial(index, step, artistSearch) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${SOCKET_ENDPOINT}/api/user/artists`, {
        index,
        step,
        artistSearch,
      });
      const results = response.data.results.length;
      const { maxLength } = response.data;

      dispatch(slice.actions.getUserListInitial(response.data.results));

      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserDetailsByScreenName(screenName) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/user/get-oneuser`,
        { screenName },
      );
      dispatch(slice.actions.getOneUserSuccess(response.data.oneUser));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// toggle follower
export function toggleFollow(senderId, receiverId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/user/social/follow`,
        { senderId, receiverId },
      );

      // dispatch(slice.actions.getUserListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// user report
export function reportUser(email, reportDesc) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${SOCKET_ENDPOINT}/api/user/report`, {
        email,
        reportDesc,
      });

      // dispatch(slice.actions.getUserListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// artists for organisation create
export function getAllArtistForOrganisation() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/user/get-artists-organisation`,
      );

      dispatch(slice.actions.getArtistListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
