import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import axios from "../../utils/axios";
import { SOCKET_ENDPOINT } from "src/utils/constants";

const initialState = {
  isLoading: false,
  error: false,
  jobCategoryList: [],
};

const slice = createSlice({
  name: "jobCategory",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE JobCategory
    getJobCategoryListSuccess(state, action) {
      state.isLoading = false;
      state.jobCategoryList = action.payload;
    },

    // CREATE JobCategory
    createJobCategorySuccess(state, action) {
      const newItem = action.payload;
      state.isLoading = false;
      state.jobCategoryList = [...state.jobCategoryList, newItem];
    },

    // UPDATE JobCategory
    updateJobCategorySuccess(state, action) {
      const item = action.payload;
      const updateItem = map(state.jobCategoryList, (e) => {
        if (e._id === item._id) {
          return item;
        }
        return e;
      });

      state.isLoading = false;
      state.jobCategoryList = updateItem;
    },

    // DELETE JobCategory
    deleteJobCategorySuccess(state, action) {
      const { itemId } = action.payload;
      const deleteItem = filter(
        state.jobCategoryList,
        (item) => item._id !== itemId,
      );
      state.isLoading = false;
      state.jobCategoryList = deleteItem;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions

export function getJobCategoryList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/manage/category/get-job-category`,
      );

      dispatch(slice.actions.getJobCategoryListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// add new job category item
export function addJobCategoryItem(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${SOCKET_ENDPOINT}/api/manage/category/add-job-category`,
        { name },
      );
      dispatch(slice.actions.createJobCategorySuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete user data
export function deleteJobCategoryItem(itemId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(
        `${SOCKET_ENDPOINT}/api/manage/category/delete-job-category`,
        { id: itemId },
      );
      dispatch(slice.actions.deleteJobCategorySuccess({ itemId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
