import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { SOCKET_ENDPOINT } from "../../utils/constants";

const initialState = {
  isLoading: false,
  error: false,
  dayAllVisitorCount: 0,
};

const slice = createSlice({
  name: "analysis",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET today visitor count
    getDayAllVisitorsSuccess(state, action) {
      state.isLoading = false;
      state.dayAllVisitorCount = action.payload;
    },

    // DELETE ARTWORK
    deleteArtwork(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions

export function getDayAllVisitorsCount() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${SOCKET_ENDPOINT}/api/day/all-visitors`,
      );
      dispatch(slice.actions.getDayAllVisitorsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
