import React, { createContext, useContext, useState } from 'react';
import { LoadScript, GoogleMap, Marker } from '@react-google-maps/api';

const GoogleMapsContext = createContext();

const GoogleMapsProvider = ({ children }) => {

    return (
        <LoadScript googleMapsApiKey="AIzaSyAttXCth0JkWuE2pUHWXMCMlzY2FVRgWbw" libraries={["places", "geometry"]}>
            <GoogleMapsContext.Provider>
                {children}
            </GoogleMapsContext.Provider>
        </LoadScript>
    );
};

const useGoogleMaps = () => useContext(GoogleMapsContext);

export {GoogleMapsProvider, useGoogleMaps}