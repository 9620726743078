export default function Menu(theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            // backgroundColor: theme.palette.action.selected,
            backgroundColor: "#4e9dfb",
            color: "#fff",
            "&:hover": {
              backgroundColor: theme.palette.action.hover,
              color: "#000",
            },
          },
        },
      },
    },
  };
}
