import * as Yup from "yup";
// import GoogleLogin from 'react-google-login';
import { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { styled } from "@mui/material/styles";
import {
  Stack,
  Alert,
  Checkbox,
  Dialog,
  DialogContent,
  TextField,
  IconButton,
  FormControlLabel,
  InputAdornment,
  Divider,
} from "@mui/material";
import countries from "../../components/countries";

import { useSelector, useDispatch } from "react-redux";
import { getJobCategoryList } from "../../redux/slices/jobCategory";

import useAuth from "../../hooks/useAuth";
import { client_id } from "src/utils/constants";

const MainContainerStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
}));

const TitleContainerStyle = styled("div")(({ theme }) => ({
  marginTop: "15px",
  marginBottom: "15px",
}));

const TitleStyle = styled("p")(({ theme }) => ({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 700,
}));

const SubTitleStyle = styled("p")(({ theme }) => ({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  cursor: "pointer"
}));

const DividerStyle = styled("div")(({ theme }) => ({
  width: "80%",
  height: "1px",
  backgroundColor: "#7C7C7C",
  margin: "30px 0 20px",
}));

const SignUpBTNStyle = styled("button")(({ theme }) => ({
  color: "#fff",
  width: "100%",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  borderRadius: "5px",
  border: "none",
  backgroundColor: "#F30E3E",
  padding: "10px 50px",
  cursor: "pointer",
  margin: "0 2%"
}));

const GoogleBTNStyle = styled("button")(({ theme }) => ({
  color: "#000",
  width: "100%",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  borderRadius: "5px",
  border: "none",
  backgroundColor: "#EDEFF1",
  padding: "10px 50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
}));

export default function SignUpArtist({
  signupArtist,
  setSignUpArtist,
  handleLoginModal,
  handleSignUpModal,
}) {
  const { register } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobCategoryList } = useSelector((state) => state.jobCategory);
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    dispatch(getJobCategoryList());
  }, [dispatch]);

  // const responseGoogle = (response) => {
  //   let requestParams = {
  //     token: response.tokenId,
  //     cd_country: country,
  //     ic_profile: profile === 1 ? 0 : 1,
  //     googleId: response.googleId
  //   };
  //   // API.post("auth/registerwithgoogle", requestParams)
  //   //   .then((result) => {
  //   //     if (result.data.status === "success") {
  //   //       navigate('/login')
  //   //     }
  //   //   })
  //   //   .catch((err) => {
  //   //   });
  // };
  // const responseGoogle1 = (response) => {
  // }

  const SignUpArtistSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    email: Yup.string().required("Email is required").email(),
    screenName: Yup.string().required("Screen Name is required"),
    jobCategory: Yup.string().required("Job is required"),
    country: Yup.string().required("country is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      role: "artist",
      fullName: "",
      email: "",
      screenName: "",
      jobCategory: "",
      country: "",
      phoneNumber: "",
      personalLink: "",
      password: "",
      // term: false,
      subscribe: false,
    },
    validationSchema: SignUpArtistSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const regexp = /^\S*$/;
        // if (!regexp.test(values.screenName)) {
        //   alert("please delete space on username");
        //   return;
        // }

        await register(
          values.role,
          values.fullName,
          values.email,
          values.screenName,
          values.jobCategory,
          values.country,
          values.phoneNumber,
          values.personalLink,
          values.password,
          // values.term,
          values.subscribe,
        );

        setSuccess(true);
        resetForm();
        // navigate("/signup/email-verify");
        window.location.reload("/");
      } catch (error) {
        setErrors({ afterSubmit: error.message });
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <Dialog
      fullWidth
      open={signupArtist}
      onClose={() => setSignUpArtist(false)}
    >
      <DialogContent>
        <MainContainerStyle>
          <TitleContainerStyle>
            <TitleStyle>Create an artist account!</TitleStyle>
            <SubTitleStyle>Beleivers in the art.</SubTitleStyle>
          </TitleContainerStyle>

          <SubTitleStyle sx={{ mb: "20px" }}>
            Already have an account?{" "}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={handleLoginModal}
            >
              Sign In
            </span>
          </SubTitleStyle>

          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <TextField name="role" sx={{ display: "none" }} />

              <Stack spacing={3}>
                {errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit}</Alert>
                )}
                {success && (
                  <Alert severity="success" color="info">
                    Registered Successfully!
                  </Alert>
                )}

                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TextField
                    fullWidth
                    label="Name"
                    size="small"
                    {...getFieldProps("fullName")}
                    error={Boolean(touched.fullName && errors.fullName)}
                    helperText={touched.fullName && errors.fullName}
                  />
                  <TextField
                    fullWidth
                    label="Email Address"
                    size="small"
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Stack>

                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TextField
                    fullWidth
                    label="User Name"
                    size="small"
                    {...getFieldProps("screenName")}
                    error={Boolean(touched.screenName && errors.screenName)}
                    helperText={touched.screenName && errors.screenName}
                  />
                  <TextField
                    select
                    fullWidth
                    label="Select your art form"
                    size="small"
                    {...getFieldProps("jobCategory")}
                    SelectProps={{ native: true }}
                    error={Boolean(touched.jobCategory && errors.jobCategory)}
                    helperText={touched.jobCategory && errors.jobCategory}
                  >
                    <option value="" />
                    {jobCategoryList?.map((item, index) => {
                      return (
                        item.name != "All" && (
                          <option key={index} value={item._id}>
                            {item.name}
                          </option>
                        )
                      );
                    })}
                  </TextField>
                </Stack>

                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TextField
                    select
                    fullWidth
                    label="Country"
                    placeholder="Country"
                    size="small"
                    {...getFieldProps("country")}
                    SelectProps={{ native: true }}
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                  >
                    <option value="" />
                    {countries.map((option) => (
                      <option key={option.code} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    size="small"
                    {...getFieldProps("phoneNumber")}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />
                </Stack>

                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TextField
                    fullWidth
                    label="Personal Website (Optional)"
                    size="small"
                    {...getFieldProps("personalLink")}
                    error={Boolean(touched.personalLink && errors.personalLink)}
                    helperText={touched.personalLink && errors.personalLink}
                  />

                  <TextField
                    fullWidth
                    autoComplete="password"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    size="small"
                    {...getFieldProps("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Stack>
              </Stack>

              <Stack sx={{ my: 2 }}>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      {...getFieldProps("term")}
                      checked={values.term}
                      color="default"
                      size="small"
                    />
                  }
                  label={
                    <SubTitleStyle>
                      By registering, I accept{" "}
                      <span style={{ fontWeight: 700 }}>General Terms</span> and{" "}
                      <span style={{ fontWeight: 700 }}>Conditions</span>.
                    </SubTitleStyle>
                  }
                /> */}

                <FormControlLabel
                  control={
                    <Checkbox
                      {...getFieldProps("subscribe")}
                      checked={values.subscribe}
                      color="default"
                      size="small"
                    />
                  }
                  label={
                    <SubTitleStyle>
                      Subscribe to Artshole newsletter
                    </SubTitleStyle>
                  }
                />
              </Stack>

              <SignUpBTNStyle type="submit">Sign Up</SignUpBTNStyle>
              <Divider sx={{ my: 1 }}>
                <SubTitleStyle>Or</SubTitleStyle>
              </Divider>
              <GoogleBTNStyle>
                <Icon icon="flat-color-icons:google" width="22" />
                &nbsp;&nbsp;&nbsp;Signup with Google
              </GoogleBTNStyle>
              {/* <div>
                <div>hahahah</div>
                <GoogleLogin
                  // className={style.googleBtn}
                  clientId={client_id}
                  buttonText={<div>Continue com Google</div>}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle1}
                  isSignedIn={true}
                />
              </div> */}
            </Form>
          </FormikProvider>

          {/* <DividerStyle />

          <SubTitleStyle
            sx={{ textDecoration: "underline" }}
            onClick={handleSignUpModal}
          >
            Are you a voter or exhibition owner?
          </SubTitleStyle> */}
        </MainContainerStyle>
      </DialogContent>
    </Dialog>
  );
}
